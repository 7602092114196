import { Button, Typography } from '@mui/material'
import { Container, StyledAlert } from './styled'
import { PaymentType } from 'types'
import GenericModal from 'components/GenericModal'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { formatToCurrency } from '../../utils'
import { ACCEPT_CREDIT } from './queries'
import { useMutation } from '@apollo/client'
import { useState } from 'react'

const DEFAULT_ERROR_MESSAGE = 'Parece que algo salió mal. Inténtalo de nuevo.'

interface ModalAcceptOperationProps {
  paymentType: PaymentType
  creditId: string
  clientName: string
  amount: number
  isOpen: boolean
  promoterName: string
  promoterBalance: number
  handleClose: () => void
  onSuccess: () => void
}

export const ModalAcceptOperation = ({
  paymentType,
  creditId,
  clientName,
  amount,
  isOpen,
  promoterName,
  promoterBalance,
  handleClose,
  onSuccess,
}: ModalAcceptOperationProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE)
  const [acceptCredit] = useMutation(ACCEPT_CREDIT)

  const loadingText = loading ? 'Aceptando' : 'Aceptar'

  const headerText =
    paymentType === PaymentType.PAYMENT_ORDER
      ? `${loadingText} orden de pago`
      : `${loadingText} transferencia bancaria`

  const bodyText =
    paymentType === PaymentType.PAYMENT_ORDER
      ? 'Se registrará la orden de pago para que se realice el desembolso para este cliente.'
      : 'Se registrará la transferencia bancaria para que se realice el desembolso para este cliente.'

  const onClose = () => {
    if (error) {
      setError(false)
      setErrorMessage(DEFAULT_ERROR_MESSAGE)
    }
    if (!loading) handleClose()
  }

  const handleClick = async () => {
    if (error) {
      setError(false)
      setErrorMessage(DEFAULT_ERROR_MESSAGE)
    }

    try {
      setLoading(true)
      const response = await acceptCredit({ variables: { creditId } })
      if (!response.errors) {
        setLoading(false)
        onSuccess()
      } else {
        setError(true)
        setLoading(false)
      }
    } catch (error) {
      const err = error as { message?: string }
      if (err?.message && err.message.includes('Promoter does not have enough credit')) {
        setErrorMessage(
          `La promotora ${promoterName} no tiene balance suficiente, actualmente es de ${formatToCurrency(
            promoterBalance,
          )}`,
        )
      }
      setError(true)
      setLoading(false)
    }
  }

  return (
    <GenericModal isOpen={isOpen} closeModalFunction={onClose}>
      <Container>
        <section>
          <Typography variant="h1">{headerText}</Typography>
          <Typography variant="body1">{bodyText}</Typography>
        </section>
        <section>
          <Typography variant="body2">Cliente</Typography>
          <Typography variant="body1">{clientName}</Typography>
        </section>
        <section>
          <Typography variant="body2">Monto</Typography>
          <Typography variant="body1">{formatToCurrency(amount)}</Typography>
        </section>
        <Button disabled={loading} color="primary" variant="contained" onClick={handleClick}>
          {headerText} <CheckCircleOutlineIcon />
        </Button>
        {error && <StyledAlert severity="error">{errorMessage}</StyledAlert>}
      </Container>
    </GenericModal>
  )
}
