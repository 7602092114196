import { InfoContainer, StyledContainer } from './styled'
import { Typography } from '@mui/material'
import { Credit } from 'types'
import { FunctionComponent } from 'react'
import { formatToCurrency } from 'utils'

interface Props {
  credit?: Credit
  promoterName: string
}

const ClientInformation: FunctionComponent<Props> = ({ credit, promoterName }: Props) => {
  const { amount, speiNumber, weeksSelected, weeklyPayment } = credit || {}

  return (
    <StyledContainer>
      <Typography variant="h3">Información del crédito</Typography>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Monto del crédito
        </Typography>
        <Typography variant="body1">{formatToCurrency(amount || 0)}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          CLABE
        </Typography>
        <Typography variant="body1">{speiNumber || 'Desconocido'}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Número de plazos
        </Typography>
        <Typography variant="body1">{weeksSelected || 8}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Pago semanal
        </Typography>
        <Typography variant="body1">{formatToCurrency(weeklyPayment || 0)}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Promotora
        </Typography>
        <Typography variant="body1">{promoterName}</Typography>
      </InfoContainer>
    </StyledContainer>
  )
}

export default ClientInformation
