import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { theme } from 'theme/theme'

export const ContainerInfoPromoter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const ContainerName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

export const ContainerOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const ContainerContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`
export const ClabeContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`
export const WarningContainer = styled.div`
  padding-left: 3%;
`

export const StyledLinkButton = styled.a`
  text-decoration: none;
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const StyledBackButton = styled(Button)`
  color: ${theme.typographyColor.accentColor};
  padding: 0px;
`

export const StyledOutlineButton = styled(Button)`
  .MuiButton-endIcon > *:nth-of-type(1) {
    font-size: 20px;
  }
`

export const PictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
`
export const ImageSelector = styled.input`
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
`
