import { FC, useState, useMemo } from 'react'
import { Container } from './styled'
import { debounce } from 'lodash'
import { Add } from '@mui/icons-material'
import Header from 'components/Header'
import { PromotersTable } from './PromotersTable'

const Promoters: FC = () => {
  const [filter, setFilter] = useState({ field: 'fullName', value: '', operator: 'contains' })
  const [isNewPromoterModalOpen, setIsNewPromoterModalOpen] = useState<boolean>(false)

  const handleSearchInput = (text: string) => {
    if (text.length >= 3) {
      const isNumber = Number.isFinite(Number(text))
      setFilter({
        field: isNumber ? 'referenceNumber' : 'fullName',
        value: text,
        operator: 'contains',
      })
    } else if (text.length === 0) {
      setFilter({ field: 'fullName', value: '', operator: 'contains' })
    }
  }

  const debouncedSearch = useMemo(() => {
    return debounce((text: string) => {
      handleSearchInput(text)
    }, 500)
  }, [])

  const openNewPromoterModal = () => setIsNewPromoterModalOpen(true)
  const closeNewPromoterModal = () => setIsNewPromoterModalOpen(false)

  return (
    <Container>
      <Header
        title="Promotoras"
        labelSearch="Buscar promotora"
        handleSearch={debouncedSearch}
        trimSearchText={filter.field === 'referenceNumber'}
        button={{
          variant: 'contained',
          label: 'Agregar promotora',
          color: 'primary',
          icon: <Add />,
          handleButton: openNewPromoterModal,
        }}
      />
      <PromotersTable
        filter={filter}
        isNewPromoterModalOpen={isNewPromoterModalOpen}
        openNewPromoterModal={openNewPromoterModal}
        closeNewPromoterModal={closeNewPromoterModal}
      />
    </Container>
  )
}

export default Promoters
