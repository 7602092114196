import React, { useState } from 'react'
import { Menu, MenuItem, Typography } from '@mui/material'
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'
import { StyledIconButton } from './styled'
import { theme } from 'theme/theme'
import ModalEditPayout from 'components/ModalEditPayout'
import GenericModal from 'components/GenericModal'

interface Props {
  clientFullName: string
  payoutID: string
  refetch: () => void
  amount: number
  paymentDate: string
  changeTab: (tab: number) => void
}

const MenuButton = ({
  clientFullName,
  amount,
  paymentDate,
  payoutID,
  refetch,
  changeTab,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const closeEditModal = () => {
    setOpenModal(false)
    handleClose()
    refetch()
  }
  const openEditModal = () => {
    setOpenModal(true)
    handleClose()
    refetch()
  }

  const handleClickMenuButton = () => {
    openEditModal()
  }

  return (
    <div>
      <StyledIconButton onClick={handleClick}>
        <MoreVertSharpIcon />
      </StyledIconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickMenuButton}>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Editar desembolso
          </Typography>
        </MenuItem>
      </Menu>
      <GenericModal isOpen={openModal} closeModalFunction={closeEditModal}>
        <ModalEditPayout
          payoutID={payoutID}
          clientFullName={clientFullName}
          moneyAmount={amount}
          payoutDate={paymentDate}
          closeEditModal={closeEditModal}
          changeTab={changeTab}
        />
      </GenericModal>
    </div>
  )
}

export default MenuButton
