import { DateFormatter } from 'react-day-picker'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { ContactData, PersonalData } from 'types'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { GridSortModel } from '@mui/x-data-grid'

export const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const formatCaption: DateFormatter = (month, options) => {
  const formattedMonth = format(month, 'LLLL', { locale: options?.locale })
  const capitalizedMonth = capitalizeFirstLetter(formattedMonth)
  return capitalizedMonth
}

export const addMaskToNumber = (input: string) => {
  return input.replace(/\s+/g, '').replace(/(\d{3})(?=\d)/g, '$1 ')
}

export const formatToCurrency = (value: number) => {
  const formatted = value.toLocaleString('es-MX', {
    style: 'currency',
    currency: 'MXN',
  })
  return formatted
}

export const formatPhoneNumber = (phoneNumber: string, withParenthesis?: boolean) => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, withParenthesis ? '($1) $2 $3' : '$1 $2 $3')
}

export enum FormattedDateEnum {
  FULL = 'FULL',
  SMALL = 'SMALL',
}

export const formattedDate = (originalDate: string, type: FormattedDateEnum) => {
  dayjs.locale('es')
  if (!originalDate) return 'Fecha inválida'
  const dayOfCalendar = dayjs(originalDate).format('D')
  const month = dayjs(originalDate).format('MMMM')
  const year = dayjs(originalDate).format('YYYY')
  if (type === FormattedDateEnum.FULL) {
    return `${dayOfCalendar} de ${capitalizeFirstLetter(month)}, ${year}`
  } else {
    return `${capitalizeFirstLetter(month.slice(0, 3))} ${dayOfCalendar}, ${year}`
  }
}

export const getFormattedAddress = (data?: ContactData) => {
  const { street, outdoorNumber, indoorNumber, suburb, zipCode } = data || {}

  if (indoorNumber) return `${street} #${outdoorNumber}, ${indoorNumber}, ${suburb} C.P. ${zipCode}`
  return `${street} #${outdoorNumber}, ${suburb} C.P. ${zipCode}`
}

export const getFullName = (data?: PersonalData) => {
  const { firstName, firstLastName, secondLastName } = data || {}
  return `${firstName || ''} ${firstLastName || ''} ${secondLastName || ''}`
}

export const removeAccents = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const optionsRowsPerPage = [10, 20, 30]
export const [INITIAL_ROWS_PER_PAGE] = optionsRowsPerPage
export const NO_ITEMS = 0

export const getInitialStateGrid = ({
  page,
  rowsPerPage,
}: {
  page: number
  rowsPerPage: number
}): GridInitialStateCommunity => ({
  pagination: {
    paginationModel: {
      page: page,
      pageSize: rowsPerPage,
    },
  },
})

export const getInitialSortModel = (): GridSortModel => [
  {
    field: 'firstName',
    sort: 'asc',
  },
]
