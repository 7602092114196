import { Typography } from '@mui/material'
import { BottomContent } from '../styled'
import { theme } from 'theme/theme'
import { FC } from 'react'
import { Prospect } from 'types'
import { Container, StyledButton } from './styled'
import IconError from '../../../assets/error.png'

type Props = {
  selectedProspect: Prospect
  handleCloseSteps: () => void
}

const RejectedClient: FC<Props> = ({ selectedProspect, handleCloseSteps }) => {
  const fullName = `${selectedProspect.firstName} ${selectedProspect.firstLastName} ${selectedProspect.secondLastName}`

  return (
    <BottomContent>
      <img src={IconError} alt="error icon" />
      <Container>
        <Typography variant="h1" color={theme.typographyColor.primaryText}>
          Prospecto Rechazado
        </Typography>
        <Typography
          variant="body1"
          color={theme.typographyColor.secondaryText}
          textAlign={'center'}
        >
          Hemos notificado a la promotora{' '}
          <Typography variant="button" component="span" color={theme.typographyColor.secondaryText}>
            {selectedProspect.createdBy}{' '}
          </Typography>
          que el prospecto{' '}
          <Typography variant="button" component="span" color={theme.typographyColor.secondaryText}>
            {fullName}{' '}
          </Typography>
          ha sido rechazado
        </Typography>
      </Container>
      <StyledButton variant="outlined" onClick={handleCloseSteps}>
        Cerrar
      </StyledButton>
    </BottomContent>
  )
}

export default RejectedClient
