import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`
