import { FC, MouseEvent, useEffect, useState } from 'react'
import { AccountContainer, AccountDetails, StyledAvatar, StyledListItemIcon } from './styled'
import { Fade, Menu, MenuItem, Typography } from '@mui/material'
import { Logout } from '@mui/icons-material'
import { theme } from 'theme/theme'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'redux/UserReducer'
import { GlobalState } from 'redux/store'

export type Roles = {
  [key: string]: string
}

export const roles: Roles = {
  Admin: 'Administrador',
}

const UserAccount: FC = () => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    role: '',
    nameInitials: '',
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const { logout } = actions
  const { user } = useSelector((state: GlobalState) => state.user)
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const awsLogout = async () => {
    try {
      await Auth.signOut()
      dispatch(logout())
    } catch (error) {
      //TODO handle error
      console.log('error signing out: ', error)
    }
  }

  const handleLogout = async () => {
    await awsLogout()
  }

  useEffect(() => {
    const role = user?.signInUserSession?.idToken?.payload['cognito:groups'][0] || ''
    const firstName = user?.signInUserSession?.idToken?.payload.given_name || ''
    const lastName = user?.signInUserSession?.idToken?.payload.family_name || ''
    const fullName = `${firstName} ${lastName}`
    const initials = `${firstName.substring(0, 1).toLocaleUpperCase()}${lastName
      .substring(0, 1)
      .toLocaleUpperCase()}`
    if (firstName && lastName && role) {
      setUserInfo({
        name: fullName,
        role: roles[role],
        nameInitials: initials,
      })
    }
  }, [user])

  return (
    <>
      <AccountContainer
        id="account-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <StyledAvatar>{userInfo.nameInitials}</StyledAvatar>
        <AccountDetails>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" color={theme.typographyColor.accentColor}>
            {userInfo.role}
          </Typography>
        </AccountDetails>
      </AccountContainer>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleLogout}>
          <StyledListItemIcon>
            <Logout />
          </StyledListItemIcon>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Cerrar sesión
          </Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserAccount
