import styled from '@emotion/styled'
import { Alert, FormHelperText, TextField } from '@mui/material'
import { theme } from 'theme/theme'

export const ContainerInfoPromoter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const ContainerName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`

export const ContainerOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledFormHelperText = styled(FormHelperText)`
  margin: 0px 16px;
  color: ${theme.typographyColor.secondaryText};
`

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const StyledAlert = styled(Alert)`
  width: 100%;
`

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${theme.extraColors?.strokeDefault};
    border-width: 1px !important;
  }
  .MuiOutlinedInput-notchedOutline:hover {
    border: 1px solid ${theme.extraColors?.strokeDefault};
  }
`
