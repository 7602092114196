import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { GridSortModel } from '@mui/x-data-grid'

export const optionsRowsPerPage = [10, 20, 30]
export const [INITIAL_ROWS_PER_PAGE] = optionsRowsPerPage
export const NO_CLIENTS = 0

export const getInitialStateGrid = ({
  page,
  rowsPerPage,
}: {
  page: number
  rowsPerPage: number
}): GridInitialStateCommunity => ({
  pagination: {
    paginationModel: {
      page: page,
      pageSize: rowsPerPage,
    },
  },
})

export const getInitialSortModel = (): GridSortModel => [
  {
    field: 'firstName',
    sort: 'asc',
  },
]
