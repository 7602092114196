import GenericModal from 'components/GenericModal'
import {
  BlueContainer,
  Container,
  StyledData,
  StyledError,
  StyledForm,
  StyledGenericInput,
  StyledInputContainer,
  StyledRadio,
} from './styled'
import { theme } from 'theme/theme'
import { FormControlLabel, FormLabel, InputAdornment, RadioGroup, Typography } from '@mui/material'
import { Bank, PromoterStructure } from 'types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { APPLY_PAYMENT, GET_ALL_BANKS, GET_PENDING_PREPAYMENTS } from './query'
import { useMutation, useQuery } from '@apollo/client'
import { AlertContext } from 'hooks/AlertContext'
import { LoadingButton } from '@mui/lab'

const LAST_DIGITS_TO_COUNT = 6
interface Props {
  isOpenModal: boolean
  promoter: PromoterStructure
  refetchPromoters: () => void
  closeModal: () => void
}

const getReferenceNumber = (phoneNumber: string) =>
  phoneNumber.substring(phoneNumber.length - LAST_DIGITS_TO_COUNT)

const ModalApplyPayment = ({ promoter, isOpenModal, refetchPromoters, closeModal }: Props) => {
  const { firstName, firstLastName, secondLastName, phoneNumber, id } = promoter
  const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
  const [openCalendar, setOpenCalendar] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>('')
  const [banks, setBanks] = useState<Bank[]>([])
  const [errorAmount, setErrorAmount] = useState<boolean>(false)
  const [errorAuthorizationNumber, setErrorAuthorizationNumber] = useState<boolean>(false)
  const [authorizationNumber, setAuthorizationNumber] = useState<string>('')
  const [bankOption, setBankOption] = useState<string>('')
  const [applyPayment, { loading: applyPaymentLoading, error: errorApplyPayment }] =
    useMutation(APPLY_PAYMENT)
  const { loading: banksLoading, data } = useQuery(GET_ALL_BANKS, {
    fetchPolicy: 'no-cache',
  })
  const [pendingPrepayments, setPendingPrepayments] = useState(0)
  const [pendingDeposits, setPendingDeposits] = useState(0)

  const { data: prePaymentTotal, refetch: refetchPrePayments } = useQuery(GET_PENDING_PREPAYMENTS, {
    variables: {
      promoterId: id,
    },
  })

  useEffect(() => {
    setPendingPrepayments(prePaymentTotal?.getPendingPrePaymentsTotalByPromoter.prepaymentTotal)
    setPendingDeposits(prePaymentTotal?.getPendingPrePaymentsTotalByPromoter.deposits)
  }, [prePaymentTotal])

  const { showSnackbar } = useContext(AlertContext)

  const onSubmitFunction = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setErrorAmount(false)
    setErrorAuthorizationNumber(false)
    if (amount.length === 0) {
      setErrorAmount(true)
    }
    if (errorApplyPayment) {
      setErrorAuthorizationNumber(true)
    }
    try {
      await applyPayment({
        variables: {
          referenceNumber: getReferenceNumber(promoter.phoneNumber),
          promoterId: id,
          bankType: bankOption,
          authorizationNumber: authorizationNumber,
          paymentAmount: parseFloat(amount),
          applicationDate: selectedDate,
        },
      })
      refetchPrePayments()
      refetchPromoters()
      closeModal()
      showSnackbar('Pago aplicado exitosamente')
    } catch (err) {
      showSnackbar('Ocurrió un problema. Inténtalo de nuevo más tarde.', 'error')
      console.log(err)
    }
  }

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9.]/g, '')
    setAmount(value)
  }
  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setBankOption(value)
  }
  const handleReferenceNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, '')
    setAuthorizationNumber(value)
  }
  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const newDate = date.format('YYYY-MM-DD')
      setSelectedDate(newDate.toString() || '')
    }
  }

  const formatToCurrency = (value: number) => {
    const formatted = value.toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })
    return formatted + ' MXN'
  }

  useEffect(() => {
    if (data) {
      setBanks(data?.getBanks)
    }
  }, [data])

  return (
    <GenericModal isOpen={isOpenModal} closeModalFunction={closeModal}>
      <Container>
        <Typography variant="h1" color={theme.typographyColor.primaryText}>
          Aplicación de pago
        </Typography>
        <Typography variant="body1" color={theme.typographyColor.secondaryText}>
          Ingresa los datos del pago y promotora a aplicar
        </Typography>
        <BlueContainer>
          <StyledData>{`Promotora: ${firstName} ${firstLastName} ${secondLastName}`}</StyledData>
          <StyledData>{`Referencia: ${getReferenceNumber(phoneNumber)}`}</StyledData>
          {pendingPrepayments > 0 && (
            <StyledData>{`Cobro de la promotora: ${formatToCurrency(
              pendingPrepayments - pendingDeposits,
            )}`}</StyledData>
          )}
        </BlueContainer>
        <StyledForm onSubmit={onSubmitFunction}>
          <StyledInputContainer>
            <StyledGenericInput
              type="number"
              error={errorAmount}
              onChange={handleAmountChange}
              InputLabelProps={{
                style: {
                  color: errorAmount
                    ? theme.typographyColor.errorText
                    : theme.typographyColor.secondaryText,
                },
              }}
              label="Monto de pago"
              autoComplete="off"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputProps: {
                  min: 1,
                  pattern: '[0-9]*',
                  step: 'any',
                },
              }}
            />
            {errorAmount && <StyledError>Ingresa un monto de pago</StyledError>}
          </StyledInputContainer>
          <div>
            <FormLabel>Banco</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              value={bankOption}
              onChange={handleBankChange}
            >
              {!banksLoading &&
                banks.map((bank) => (
                  <FormControlLabel
                    key={bank.id}
                    control={
                      <StyledRadio
                        value={bank.enumBankName}
                        checked={bankOption === bank.enumBankName}
                      />
                    }
                    label={bank.bankName}
                  />
                ))}
            </RadioGroup>
          </div>
          <StyledInputContainer>
            <StyledGenericInput
              type="number"
              onChange={handleReferenceNumberChange}
              InputLabelProps={{
                style: {
                  color: errorAuthorizationNumber
                    ? theme.typographyColor.errorText
                    : theme.typographyColor.secondaryText,
                },
              }}
              error={errorAuthorizationNumber}
              label="Número de Autorización"
              autoComplete="off"
              InputProps={{
                inputProps: {
                  min: 1,
                  pattern: '[0-9]*',
                },
              }}
            />
            {errorAuthorizationNumber && (
              <StyledError>La referencia ya ha sido capturada, favor de validar.</StyledError>
            )}
          </StyledInputContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
            <DatePicker
              open={openCalendar}
              onClose={() => setOpenCalendar(false)}
              disableFuture={true}
              defaultValue={dayjs()}
              onChange={(newDate: Dayjs | null) => handleDateChange(newDate)}
              slotProps={{
                inputAdornment: { position: 'start' },
                textField: {
                  fullWidth: true,
                  placeholder: 'Selecciona una fecha',
                  onClick: () => setOpenCalendar(true),
                },
              }}
              format="dddd LL"
              label="Fecha de aplicación"
            />
          </LocalizationProvider>
          <LoadingButton
            disabled={
              selectedDate.length === 0 ||
              amount.length === 0 ||
              parseFloat(amount) > pendingPrepayments - pendingDeposits ||
              authorizationNumber.length === 0 ||
              bankOption.length === 0
            }
            type="submit"
            variant="contained"
            size="large"
            loading={applyPaymentLoading}
            fullWidth
          >
            <Typography variant="button">Aplicar Pago</Typography>
          </LoadingButton>
        </StyledForm>
      </Container>
    </GenericModal>
  )
}

export default ModalApplyPayment
