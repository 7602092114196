import styled from '@emotion/styled'
import { Avatar } from '@mui/material'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledValidationContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
`

export const StyledTabsContainer = styled.div`
  display: flex;
  gap: 24px;

  flex: 1;
`

export const StyledRightColumn = styled.div`
  flex: 2;
`

export const StyledLeftColumn = styled.div`
  flex: 1;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--system-colors-stroke-01, #eee);
  background: var(--backgrounds-paper-03, #fff);
`
export const StyledAvatar = styled(Avatar)`
  width: 108px;
  height: 108px;
`
export const StyledSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 40px;
`
