import { gql } from '@apollo/client'

export const GET_CLIENT_DETAILS = gql`
  query GetClientsById($getClientsByIdId: String!) {
    getClientsById(id: $getClientsByIdId) {
      id
      firstName
      firstLastName
      secondLastName
      phoneNumber
      phoneLada
      imageUrl
      status
      rejections {
        id
        reason
        internalReason
        contactData
        economicData
        personalData
        createdAt
        updatedAt
        type
        status
      }
      acceptedAt
      createdAt
      createdBy
      economicData {
        id
        housingType
        ageOfHousing
        economicDependants
        incomes {
          id
          incomeType
          description
          isMain
          amount
          periodicity
          nameOfEmployer
          workExperience
          employmentPosition
          economicDataId
        }
      }
      contactData {
        id
        phoneNumber
        phoneLada
        phoneVerified
        outsideAddressPhotoUrl
        insideAddressPhotoUrl
        latitude
        longitude
        indoorNumber
        outdoorNumber
        state
        zipCode
        suburb
        state
        municipality
        street
      }
      personalData {
        id
        firstName
        firstLastName
        secondLastName
        maritalStatus
        frontINEUrl
        backINEUrl
        proofOfAddressUrl
        proofOfAddress
        photoUrl
      }
    }
  }
`
