import { SizeEmptyErrorState } from 'types'
import { EmptyContainer } from 'components/common/styled'
import EmptyErrorState from 'components/EmptyErrorState'
import ImageNoOrders from 'assets/paymentOrder.png'

interface NoDataProps {
  message: string
  buttonLabel?: string
  leftIconButton?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  handleButton?: () => void
}

export const NoData = ({ message, buttonLabel, leftIconButton, handleButton }: NoDataProps) => {
  return (
    <EmptyContainer paddingVertical={65}>
      <EmptyErrorState
        icon={ImageNoOrders}
        size={SizeEmptyErrorState.LARGE}
        textDescription={message}
        buttonLabel={buttonLabel}
        leftIconButton={leftIconButton}
        handleButton={handleButton}
      />
    </EmptyContainer>
  )
}
