import DMSSANSRegular from './fontAssets/DMSans-Regular.ttf'
import DMSSANSMedium from './fontAssets/DMSans-Medium.ttf'
import DMSANSBold from './fontAssets/DMSans-Bold.ttf'
import DMSANSItalic from './fontAssets/DMSans-Italic.ttf'

export const DMSansRegular = {
  fontFamily: 'DMSansRegular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${DMSSANSRegular}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const DMSansMedium = {
  fontFamily: 'DMSansMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '500',
  src: `url(${DMSSANSMedium}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const DMSansBold = {
  fontFamily: 'DMSansBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${DMSANSBold}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties

export const DMSansItalic = {
  fontFamily: 'DMSansItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `url(${DMSANSItalic}) format('truetype')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  MozFontFeatureSettings: '{}',
  fontFeatureSettings: '{}',
  fontStretch: '{}',
  fontVariationSettings: '{}',
  fontVariant: '{}',
} as React.CSSProperties
