import { FC, useState, useEffect } from 'react'
import GenericModal from 'components/GenericModal'
import SearchPromoters from './SearchPromoters'
import AddPromoter from './AddPromoter'
import PromoterAddedSuccessfully from './PromoterAddedSucessfully'
import { Container } from './styled'

export type Promoter = {
  id: string
  firstName: string
  firstLastName: string
  secondLastName: string
  phoneNumber: string
  speiNumber: string
}

export enum StatusPromoter {
  ACTIVE,
  INACTIVE,
}

type Props = {
  isOpenModal: boolean
  closeModal: () => void
  getAllPromoters: () => void
}

enum STEP {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

const INITIAL_STEP = 1

const ModalAddNewPromoter: FC<Props> = ({ isOpenModal, closeModal, getAllPromoters }) => {
  const [actualStep, setActualStep] = useState<STEP>(INITIAL_STEP)
  const [selectedPromoter, setSelectedPromoter] = useState<Promoter | null>(null)

  useEffect(() => {
    if (!isOpenModal) {
      setActualStep(INITIAL_STEP)
    }
  }, [isOpenModal])

  const handleChangeStep = (step: number) => setActualStep(step)

  const handleSelectedPromoter = (promoter: Promoter | null) => setSelectedPromoter(promoter)

  const handleCloseSteps = () => {
    if (actualStep === STEP.THREE) {
      getAllPromoters()
    }
    closeModal()
  }

  const stepsComponents = {
    [STEP.ONE]: (
      <SearchPromoters
        isOpenModal={isOpenModal}
        handleChangeStep={handleChangeStep}
        handleSelectedPromoter={handleSelectedPromoter}
      />
    ),
    [STEP.TWO]: (
      <AddPromoter
        selectedPromoter={selectedPromoter}
        isOpenModal={isOpenModal}
        handleChangeStep={handleChangeStep}
        handleSelectedPromoter={handleSelectedPromoter}
      />
    ),
    [STEP.THREE]: (
      <PromoterAddedSuccessfully
        selectedPromoter={selectedPromoter}
        handleCloseSteps={handleCloseSteps}
      />
    ),
  }

  return (
    <GenericModal isOpen={isOpenModal} closeModalFunction={handleCloseSteps}>
      <Container>{stepsComponents[actualStep]}</Container>
    </GenericModal>
  )
}

export default ModalAddNewPromoter
