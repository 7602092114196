import { CustomToolbarProps } from 'components/CustomDataGrid'
import { PaymentType } from 'types'
import { Button, Typography } from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { theme } from 'theme/theme'
import { ButtonsContainer, StyledGridToolbarContainer } from './styled'

const texts = {
  [PaymentType.SPEI]: 'SPEI',
  [PaymentType.PAYMENT_ORDER]: 'Orden de Pago',
}

export function CustomToolbar({
  title,
  filterButton,
  paymentType,
  filterTypeButtons,
}: CustomToolbarProps) {
  const isSpei = paymentType === PaymentType.SPEI
  const isPaymentOrder = paymentType === PaymentType.PAYMENT_ORDER
  const { onClick, disabled } = filterTypeButtons || {}

  return (
    <StyledGridToolbarContainer>
      <Typography variant="body1" color={theme.typographyColor.primaryText}>
        {title}
      </Typography>
      <ButtonsContainer>
        <Button
          sx={{ minWidth: 135, minHeight: 45, borderRadius: 2 }}
          disabled={disabled}
          color={isSpei ? 'secondary' : 'primary'}
          variant="contained"
          onClick={() => onClick?.(PaymentType.SPEI)}
        >
          <Typography variant="body1" color={isSpei ? 'white' : theme.typographyColor.primaryText}>
            {texts[PaymentType.SPEI]}
          </Typography>
        </Button>
        <Button
          sx={{ minWidth: 135, minHeight: 45, borderRadius: 2 }}
          disabled={disabled}
          color={isPaymentOrder ? 'secondary' : 'primary'}
          variant="contained"
          onClick={() => onClick?.(PaymentType.PAYMENT_ORDER)}
        >
          <Typography
            variant="body1"
            color={isPaymentOrder ? 'white' : theme.typographyColor.primaryText}
          >
            {texts[PaymentType.PAYMENT_ORDER]}
          </Typography>
        </Button>
      </ButtonsContainer>
      {filterButton && (
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={filterButton.handleClick}
          startIcon={<FilterList />}
        >
          Filtros
        </Button>
      )}
    </StyledGridToolbarContainer>
  )
}
