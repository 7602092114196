import {
  ButtonContainer,
  Container,
  DocumentImg,
  ImageContainer,
  StyledIconContainer,
  StyledToggleButton,
  TextContainer,
} from './styled'
import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import ineFrontIcon from 'assets/ineFrontIcon.svg'
import ineBackIcon from 'assets/ineBackIcon.svg'
import docIcon from 'assets/docIcon.svg'
import EmptyErrorState from 'components/EmptyErrorState'
import ImageError from 'assets/error.png'
import {
  SizeEmptyErrorState,
  DocumentsType,
  PersonalData,
  ProofOfAddressEnum,
  ContactData,
} from 'types'
import { defaultContactData, defaultPersonalData } from 'screens/Validator/values'
import { ApolloError } from 'apollo-client'

interface Props {
  personalData?: PersonalData
  contactUser?: ContactData
  error?: ApolloError
  refetchUser: () => void
}
const CustomTabs = ({ personalData, error, refetchUser, contactUser }: Props) => {
  const [selected, setSelected] = useState<number>(1)
  const [data, setData] = useState<PersonalData>()
  const [prospectData, setProspectData] = useState<ContactData>()

  const returnDocumentLabel = (documentType: string) => {
    switch (documentType) {
      case ProofOfAddressEnum.BANK_ACCOUNT:
        return 'Estado de cuenta'
      case ProofOfAddressEnum.CFE:
        return 'Recibo de luz'
      case ProofOfAddressEnum.TELMEX:
        return 'Recibo de teléfono'
      case ProofOfAddressEnum.PLACE:
        return 'Renta'
      case ProofOfAddressEnum.WATER:
        return 'Recibo de agua'
      default:
        return null
    }
  }

  const evaluateProofOfAddress = (proofOfAddress: string): number => {
    switch (proofOfAddress) {
      case ProofOfAddressEnum.BANK_ACCOUNT:
        return 6
      case ProofOfAddressEnum.CFE:
        return 3
      case ProofOfAddressEnum.PLACE:
        return 7
      case ProofOfAddressEnum.TELMEX:
        return 5
      case ProofOfAddressEnum.WATER:
        return 4
      default:
        return 0
    }
  }

  const renderContent = (selected: number, error: ApolloError | undefined) => {
    if (error) {
      return (
        <EmptyErrorState
          icon={ImageError}
          size={SizeEmptyErrorState.LARGE}
          textDescription="Parece que algo salió mal al cargar la imagen"
          buttonLabel="Volver a intentar"
          handleButton={refetchUser}
        ></EmptyErrorState>
      )
    } else {
      switch (selected) {
        case DocumentsType.INEFRONT:
          return <DocumentImg src={data?.frontINEUrl} alt="ineFront" />
        case DocumentsType.INEBACK:
          return <DocumentImg src={data?.backINEUrl} alt="ineBack" />
        case DocumentsType.BANK_ACCOUNT:
        case DocumentsType.CFE:
        case DocumentsType.PHONE:
        case DocumentsType.PLACE:
        case DocumentsType.WATER:
          return <DocumentImg src={data?.proofOfAddressUrl} alt="doc" />
        case DocumentsType.OUTSIDE_HOUSE:
          return <DocumentImg src={prospectData?.outsideAddressPhotoUrl} alt="outsideHouse" />
        case DocumentsType.INSIDE_HOUSE:
          return <DocumentImg src={prospectData?.insideAddressPhotoUrl} alt="insideHouse" />
        default:
          return null
      }
    }
  }

  const handleButtonClick = (value: number) => {
    if (selected !== value) {
      setSelected(value)
    }
  }

  useEffect(() => {
    setData(personalData || defaultPersonalData)
    setProspectData(contactUser || defaultContactData)
  }, [personalData, contactUser])

  return (
    <Container>
      <ImageContainer>{renderContent(selected, error)}</ImageContainer>

      <ButtonContainer>
        <StyledToggleButton
          aria-label="list"
          value={DocumentsType.INEFRONT}
          selected={selected === DocumentsType.INEFRONT}
          onClick={() => handleButtonClick(DocumentsType.INEFRONT)}
        >
          <img src={ineFrontIcon} alt="icon" />
          <TextContainer>
            <Typography variant="body3">INE Frontal</Typography>
          </TextContainer>
        </StyledToggleButton>

        <StyledToggleButton
          value={DocumentsType.INEBACK}
          selected={selected === DocumentsType.INEBACK}
          onClick={() => handleButtonClick(DocumentsType.INEBACK)}
        >
          <img src={ineBackIcon} alt="icon" />
          <TextContainer>
            <Typography variant="body3">INE Reverso</Typography>
          </TextContainer>
        </StyledToggleButton>

        <StyledToggleButton
          value={evaluateProofOfAddress(data?.proofOfAddress || '')}
          selected={
            selected === DocumentsType.CFE ||
            selected === DocumentsType.BANK_ACCOUNT ||
            selected === DocumentsType.PHONE ||
            selected === DocumentsType.PLACE ||
            selected === DocumentsType.WATER
          }
          onClick={() => handleButtonClick(evaluateProofOfAddress(data?.proofOfAddress || ''))}
        >
          <StyledIconContainer src={docIcon} alt="icon" />
          <TextContainer>
            <Typography variant="body3">
              {returnDocumentLabel(data?.proofOfAddress || '')}
            </Typography>
          </TextContainer>
        </StyledToggleButton>

        <StyledToggleButton
          value={DocumentsType.OUTSIDE_HOUSE}
          selected={selected === DocumentsType.OUTSIDE_HOUSE}
          onClick={() => handleButtonClick(DocumentsType.OUTSIDE_HOUSE)}
        >
          <StyledIconContainer src={docIcon} alt="icon" />
          <TextContainer>
            <Typography variant="body3">Fachada de la casa</Typography>
          </TextContainer>
        </StyledToggleButton>

        <StyledToggleButton
          value={DocumentsType.INSIDE_HOUSE}
          selected={selected === DocumentsType.INSIDE_HOUSE}
          onClick={() => handleButtonClick(DocumentsType.INSIDE_HOUSE)}
        >
          <StyledIconContainer src={docIcon} alt="icon" />
          <TextContainer>
            <Typography variant="body3">Dentro de la casa</Typography>
          </TextContainer>
        </StyledToggleButton>
      </ButtonContainer>
    </Container>
  )
}

export default CustomTabs
