import {
  INITIAL_ROWS_PER_PAGE,
  NO_CLIENTS,
  getInitialSortModel,
  getInitialStateGrid,
} from '../utils'
import { GridPaginationModel } from '@mui/x-data-grid'
import { getPromotersColumns } from 'components/CustomDataGrid/Columns'
import { PromoterStructure } from 'types'
import { GET_ALL_PROMOTERS } from '../queries'
import { NetworkError } from 'components/CustomDataGrid/EmptyStates/NetworkError'
import { RequestError } from 'components/CustomDataGrid/EmptyStates/RequestError'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { NoData } from 'components/CustomDataGrid/EmptyStates/NoData'
import { Add } from '@mui/icons-material'
import ModalAddNewPromoter from 'components/ModalAddNewPromoter'
import ModalApplyPayment from 'components/ModalApplyPayment'
import CustomDataGrid from 'components/CustomDataGrid'

const promoterObject: PromoterStructure = {
  id: 1,
  firstName: 'John',
  firstLastName: 'Doe',
  secondLastName: 'Smith',
  phoneNumber: '123-456-7890',
  createdAt: '2023-01-01',
  status: 'Active',
}

interface PromotersTableProps {
  filter: { field: string; value: string; operator: string }
  isNewPromoterModalOpen: boolean
  openNewPromoterModal: () => void
  closeNewPromoterModal: () => void
}

export const PromotersTable = ({
  filter,
  isNewPromoterModalOpen,
  openNewPromoterModal,
  closeNewPromoterModal,
}: PromotersTableProps) => {
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)
  const [total, setTotal] = useState(0)
  const [promoter, setPromoter] = useState<PromoterStructure>(promoterObject)
  const [modalApplyPayment, setModalApplyPayment] = useState<boolean>(false)

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const [promoters, setPromoters] = useState<PromoterStructure[]>([])
  const {
    loading,
    error,
    data,
    refetch: getAllPromoters,
  } = useQuery(GET_ALL_PROMOTERS, {
    variables: {
      page: page,
      limit: rowsPerPage,
      search: filter.value,
      orderBy: 'firstName',
      orderDirection: 'asc',
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!loading && data) {
      setPromoters(data?.getPromoters?.promoters || [])
      setTotal(data?.getPromoters?.total || 0)
    }
  }, [data, loading])

  const initialStateGrid = getInitialStateGrid({ page, rowsPerPage })
  const initialSortModel = getInitialSortModel()

  const handleChangePage = ({ page, pageSize }: GridPaginationModel) => {
    setPage(page)
    setRowsPerPage(pageSize)
  }

  const handleErrorButton = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      getAllPromoters()
    }
  }

  const closeModalApplyPayment = () => setModalApplyPayment(false)
  const openModalApplyPayment = () => setModalApplyPayment(true)

  const hasNoData = promoters.length === NO_CLIENTS && filter.value.length === 0 && !loading

  if (!isOnlineState) return <NetworkError handleRetry={handleErrorButton} />
  if (error) return <RequestError handleRetry={handleErrorButton} />
  if (hasNoData)
    return (
      <NoData
        message="Aún no tienes promotoras agregadas, comienza agregando una"
        buttonLabel="Agregar promotora"
        handleButton={openNewPromoterModal}
        leftIconButton={<Add />}
      />
    )

  return (
    <>
      <CustomDataGrid
        loading={loading}
        data={promoters}
        dataColumns={getPromotersColumns({
          setPromoter,
          openApplyPayment: openModalApplyPayment,
        })}
        nameFilter={filter}
        title="Listado de promotoras"
        sortingMode="server"
        filterMode="client"
        paginationMode="server"
        totalRows={total}
        handleChangePage={handleChangePage}
        initialState={initialStateGrid}
        sort={initialSortModel}
      />
      {isNewPromoterModalOpen && (
        <ModalAddNewPromoter
          isOpenModal={isNewPromoterModalOpen}
          closeModal={closeNewPromoterModal}
          getAllPromoters={getAllPromoters}
        />
      )}
      {modalApplyPayment && (
        <ModalApplyPayment
          promoter={promoter}
          isOpenModal={modalApplyPayment}
          closeModal={closeModalApplyPayment}
          refetchPromoters={getAllPromoters}
        />
      )}
    </>
  )
}
