import { AlertColor } from '@mui/material'
import Snackbar from 'components/common/Snackbar'
import { createContext, ReactNode, FunctionComponent, useState } from 'react'

type InitialState = {
  openSnack: boolean
  messageText: string
  type: AlertColor
}

const initialState: InitialState = {
  openSnack: false,
  messageText: '',
  type: 'success',
}

export const AlertContext = createContext<AlertProviderProps>({} as AlertProviderProps)

interface AlertProviderProps {
  showSnackbar: (messageText: string, type?: AlertColor) => void
  closeSnackbar: () => void
}

interface Props {
  children: ReactNode
}

const Alert: FunctionComponent<Props> = ({ children }: Props) => {
  const [alert, setAlert] = useState<InitialState>(initialState)

  const showSnackbar = (messageText: string, type?: AlertColor) =>
    setAlert({ openSnack: true, messageText, type: type || 'success' })

  const closeSnackbar = () => setAlert({ ...alert, openSnack: false })

  const { openSnack, messageText, type } = alert

  const defaultContext: AlertProviderProps = {
    showSnackbar,
    closeSnackbar,
  }

  return (
    <AlertContext.Provider value={defaultContext}>
      <Snackbar open={openSnack} messageText={messageText} type={type} onClose={closeSnackbar} />
      {children}
    </AlertContext.Provider>
  )
}

export default Alert
