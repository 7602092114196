import { Typography } from '@mui/material'
import { RecoverPasswordContainer, ArrowBackIconButton } from '../styled'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { theme } from '../../../theme/theme'

interface Props {
  goBackFunction: () => void
}

const RecoverPassword = ({ goBackFunction }: Props) => {
  return (
    <RecoverPasswordContainer>
      <ArrowBackIconButton startIcon={<ArrowBackIcon />} onClick={goBackFunction}>
        <Typography variant="body1" color={theme.typographyColor.primaryText}>
          Atrás
        </Typography>
      </ArrowBackIconButton>

      <Typography variant="h1" color={theme.typographyColor.primaryText}>
        Recuperación de Contraseña
      </Typography>

      <Typography variant="body1" color={theme.typographyColor.secondaryText}>
        Para recuperar tu contraseña contacta al administrador en el siguiente correo:
      </Typography>
      <Typography variant="h3" color={theme.typographyColor.accentColor}>
        admin.support@fonicredito.com
      </Typography>
    </RecoverPasswordContainer>
  )
}

export default RecoverPassword
