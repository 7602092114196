import { FC, useState, useEffect, useMemo } from 'react'
import { Prospect, StatusClientEnum } from 'types'
import CustomDataGrid from 'components/CustomDataGrid'
import { SmartToyOutlined } from '@mui/icons-material/'
import { useNavigate } from 'react-router-dom'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { GET_PROMOTERS_BY_STATUS } from '../queries'
import { Button } from '@mui/material'
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid'
import { StyledChip } from './Chip'
import { actions } from 'redux/ProspectReducer'
import { useDispatch } from 'react-redux'
import {
  FormattedDateEnum,
  formattedDate,
  removeAccents,
  INITIAL_ROWS_PER_PAGE,
  getInitialStateGrid,
  optionsRowsPerPage,
} from 'utils'
import { NetworkError, NoData, RequestError } from 'components/CustomDataGrid/EmptyStates'

const NO_PROSPECTS = 0
const STEP_ID = 1

type Props = {
  totalItems: number
  filter: string
  actualStep: number
}

const Pending: FC<Props> = ({ totalItems, actualStep, filter }) => {
  const [filterSearchProspects, setFilterSearchProspects] = useState<string>('')
  const [prospectsPending, setProspectsPending] = useState<Prospect[]>([])
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const initialStateGrid = getInitialStateGrid({ page, rowsPerPage })
  const queryVars = useMemo(
    () => ({
      fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
      notifyOnNetworkStatusChange: true,
      variables: {
        page: page,
        limit: rowsPerPage,
        search: filter,
        orderBy: 'createdAt',
        statuses: [StatusClientEnum.PENDING],
      },
    }),
    [page, rowsPerPage, filter],
  )
  const { loading, data, error, refetch } = useQuery(GET_PROMOTERS_BY_STATUS, queryVars)
  const { setProspectDataReducer } = actions
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const pendingColumns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Nombre',
      flex: 1.2,
      valueGetter: (params) => {
        const { firstName, firstLastName, secondLastName } = params.row
        const fullName = removeAccents(`${firstName} ${firstLastName} ${secondLastName}`)
        return `${fullName}`
      },
      renderCell: ({ row }: GridRenderCellParams) => {
        const { firstName, firstLastName, secondLastName } = row
        return `${firstName} ${firstLastName} ${secondLastName}`
      },
    },
    {
      field: 'createdBy',
      headerName: 'Promotora',
      flex: 1.2,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { createdBy } = row
        return createdBy
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de solicitud',
      flex: 0.7,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const date = formattedDate(value as string, FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'status',
      headerName: 'Estatus Verificación Automatizada',
      flex: 0.7,
      renderCell: ({ row }: GridRenderCellParams) => {
        let statusMessage = ''
        let status = StatusClientEnum.CREATED
        if (row.status === StatusClientEnum.ACCEPTED) {
          statusMessage = 'Aceptado'
          status = StatusClientEnum.ACCEPTED
        } else if (row.status === StatusClientEnum.REJECTED) {
          statusMessage = 'Rechazado'
          status = StatusClientEnum.REJECTED
        } else {
          statusMessage = 'Pendiente'
        }
        return <StyledChip icon={<SmartToyOutlined />} label={statusMessage} state={status} />
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      align: 'right',
      getActions: ({ row }: GridRowParams) => [
        <Button
          key={row.id}
          variant={'outlined'}
          color={'primary'}
          onClick={() => {
            navigate(`/validate/${row.id}`)
            dispatch(setProspectDataReducer(row))
          }}
        >
          Verificar
        </Button>,
      ],
    },
  ]

  const handleReloadInfo = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      refetch()
    }
  }

  const handleChangePage = ({ page, pageSize }: GridPaginationModel) => {
    setPage(page)
    setRowsPerPage(pageSize)
  }

  useEffect(() => {
    if (actualStep === STEP_ID) {
      setFilterSearchProspects(filter)
    }
  }, [actualStep, filter])

  useEffect(() => {
    if (!loading && data) {
      setProspectsPending(data?.getClientsListByStatus)
    }
  }, [data, loading])

  const hasNoData = prospectsPending.length === NO_PROSPECTS && loading === false

  if (!isOnlineState) return <NetworkError handleRetry={handleReloadInfo} />
  if (error) return <RequestError handleRetry={handleReloadInfo} />
  if (hasNoData) return <NoData message="No hay ningún prospecto pendiente para verificar" />

  return (
    <CustomDataGrid
      loading={loading}
      data={prospectsPending}
      dataColumns={pendingColumns}
      nameFilter={filterSearchProspects}
      title="Listado de prospectos pendientes de verificar"
      filterMode="client"
      totalRows={totalItems}
      handleChangePage={handleChangePage}
      initialState={initialStateGrid}
      pageSizeOptions={optionsRowsPerPage}
      paginationMode="server"
    />
  )
}

export default Pending
