import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat } from '@apollo/client'
import { REACT_APP_LENDING_GRAPHQL_URL } from 'config/appConfig'

const httpLink = new HttpLink({ uri: REACT_APP_LENDING_GRAPHQL_URL })

const authMiddleware = new ApolloLink((operation, forward) => {
  const localStorageKeys = Object.keys(localStorage) || []
  const tokenKey = localStorageKeys.find((key) => key.includes('idToken')) || ''
  const token = localStorage.getItem(tokenKey) || ''

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token || ''}`,
    },
  }))
  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})
