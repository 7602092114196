import styled from '@emotion/styled'
import { theme } from 'theme/theme'

interface ContainerProps {
  width?: number
}

export const Container = styled.div<ContainerProps>`
  border-width: 1px;
  border-radius: 8px;
  border-color: ${theme.extraBackgrounds.paper02};
  background-color: ${theme.extraBackgrounds.paper02};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  gap: 16px;
`

interface StyledButtonProps {
  cursorPointer?: boolean
  isVisible?: boolean
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'default')};
  visibility: ${({ isVisible }) => (isVisible ? 'initial' : 'hidden')};
  color: ${theme.typographyColor.iconBasicColor};
  opacity: 0.8;

  &:focus {
    outline: none;
  }
`

export const StyledTextInput = styled.input`
  height: 24px;
  border: 0px;
  background-color: unset;
  padding: 0px;
  font-family: ${theme.typography.fontFamily};
  color: ${theme.typographyColor.primaryText};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex: 4;

  &::placeholder {
    color: ${theme.typographyColor.primaryText};
  }

  &:focus {
    outline: none;
  }
`
