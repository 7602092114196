import { FC } from 'react'
import {
  BottomContent,
  Container,
  ContainerOption,
  LogoIcon,
  MenuList,
  Option,
  TopContent,
} from './styled'
import LogoFoni from '../../assets/logoFoni.svg'
import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'
import UserAccount from 'components/UserAccount'

export type RouteType = {
  path: string
  label: string
}

/**
 * /*
{ path: '/banktransfers', label: 'Transferencias bancarias' },
*/

export const routes: RouteType[] = [
  { path: '/promoters', label: 'Promotoras' },
  { path: '/prospects', label: 'Prospectos' },
  { path: '/paymentorders', label: 'Órdenes de pago' },
]

const Sidebar: FC = () => {
  const { pathname } = useLocation()

  return (
    <Container>
      <TopContent>
        <LogoIcon src={LogoFoni} alt="Logo" />
        <MenuList>
          {routes.map(({ label, path }, index) => (
            <ContainerOption key={index}>
              <Option to={path} selected={path === pathname}>
                <Typography variant="button">{label}</Typography>
              </Option>
            </ContainerOption>
          ))}
        </MenuList>
      </TopContent>
      <BottomContent>
        <UserAccount />
      </BottomContent>
    </Container>
  )
}

export default Sidebar
