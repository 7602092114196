import styled from '@emotion/styled'
import { Button } from '@mui/material'

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const ContainerInput = styled.div`
  width: 100%;
`

export const StyledButton = styled(Button)`
  padding: 12px 20px;
`

export const AnimateLoading = styled.img`
  animation-name: smart-animate;
  animation-delay: 1ms;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: fit-content;

  @keyframes smart-animate {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

export const ContainerElement = styled.div`
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
`
