import { Button, Typography } from '@mui/material'
import {
  Container,
  NameContainer,
  StyledAlert,
  StyledSuccessContainer,
  StyledSuccessTitle,
  TitleContainer,
  TypographyLabel,
  TypographyNameLabel,
  TypographySuccessDescriptionLabel,
  TypographySuccessLabel,
} from './styled'
import { LoadingButton } from '@mui/lab'
import checkmarkPink from 'assets/checkmarkPink.png'
import { useMutation } from '@apollo/client'
import { ACCEPT_CLIENT } from './mutation'
import { useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'

interface Props {
  handleClose: () => void
  changePage: () => void
  nextPath: boolean
}

const ModalAcceptProspect = ({ handleClose, changePage, nextPath }: Props) => {
  const { prospect: prospectData } = useSelector((state: GlobalState) => state.prospect)
  const [acceptClient, { loading, error }] = useMutation(ACCEPT_CLIENT)

  const handleClickButton = async () => {
    await handleAcceptClient()
  }

  const handleFinishProspectAccepted = () => {
    handleClose()
  }

  const handleAcceptClient = async () => {
    try {
      await acceptClient({
        variables: { acceptClientId: prospectData?.id },
      })
      changePage()
    } catch (error) {
      //TODO handle error
    }
  }

  return (
    <div>
      {!nextPath ? (
        <Container>
          <TitleContainer>
            <Typography variant="h1">Aceptar Prospecto</Typography>
            <Typography variant="body1">
              Al aceptar este prospecto, se le notificará a la promotora y podrá otorgarle nuevos
              créditos
            </Typography>
          </TitleContainer>
          <NameContainer>
            <TypographyLabel variant="body2">Prospecto</TypographyLabel>

            <TypographyNameLabel variant="body1">{`${prospectData?.firstName} ${prospectData?.firstLastName} ${prospectData?.secondLastName}`}</TypographyNameLabel>
          </NameContainer>
          {error && (
            <StyledAlert severity="error">
              Parece que algo salio mal. Inténtalo de nuevo.
            </StyledAlert>
          )}
          <LoadingButton
            variant="contained"
            size="large"
            loading={loading}
            onClick={handleClickButton}
            fullWidth
          >
            {' '}
            <Typography variant="button">Aceptar Prospecto</Typography>
          </LoadingButton>
        </Container>
      ) : (
        <StyledSuccessContainer>
          <img src={checkmarkPink} alt="checkmarkIcon" />
          <StyledSuccessTitle>
            <TypographySuccessLabel variant="h1">¡Prospecto Aceptado!</TypographySuccessLabel>{' '}
            <TypographySuccessDescriptionLabel variant="body1">
              Hemos notificado a la promotora{' '}
              <Typography variant="body4"> {prospectData?.createdBy}</Typography> que el prospecto{' '}
              <Typography variant="body4">
                {' '}
                {`${prospectData?.firstName} ${prospectData?.firstLastName} ${prospectData?.secondLastName}`}
              </Typography>{' '}
              ha sido aceptado
            </TypographySuccessDescriptionLabel>
          </StyledSuccessTitle>
          <Button variant="outlined" fullWidth onClick={handleFinishProspectAccepted}>
            Ir a prospectos
          </Button>
        </StyledSuccessContainer>
      )}
    </div>
  )
}

export default ModalAcceptProspect
