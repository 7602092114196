import { FC } from 'react'
import { AlertColor, Slide, SlideProps, Typography } from '@mui/material'
import { StyledAlert, StyledSnackbarAlert } from './styled'
import { CheckCircleOutline } from '@mui/icons-material'

type TransitionProps = Omit<SlideProps, 'direction'>

const TransitionLeft = (props: TransitionProps) => {
  return <Slide {...props} direction="left" />
}

interface Props {
  messageText: string
  open: boolean
  type?: AlertColor
  onClose: () => void
}

const Snackbar: FC<Props> = ({ messageText, open, type, onClose }: Props) => {
  return (
    <StyledSnackbarAlert
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      key={messageText}
      autoHideDuration={6000}
      TransitionComponent={TransitionLeft}
      onClose={onClose}
    >
      <StyledAlert icon={<CheckCircleOutline />} severity={type || 'success'}>
        <Typography variant="alertText">{messageText}</Typography>
      </StyledAlert>
    </StyledSnackbarAlert>
  )
}

export default Snackbar
