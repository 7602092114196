import styled from '@emotion/styled'
import { ListItemIcon, Avatar } from '@mui/material'
import { theme } from 'theme/theme'

export const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
  word-break: break-all;
`

export const StyledAvatar = styled(Avatar)`
  background-color: ${theme.extraBackgrounds.selectedItem};
  color: ${theme.typographyColor.primaryText};
  width: 40px;
  height: 40px;
`

export const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`

export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${theme.typographyColor.iconBasicColor};
`
