import { gql } from '@apollo/client'

export const GET_ALL_BANKS = gql`
  query GetBanks {
    getBanks {
      id
      bankName
      beneficiaryName
      accountNumber
      enumBankName
      createdAt
      updatedAt
    }
  }
`
export const GET_PENDING_PREPAYMENTS = gql`
  query GetPendingPrePaymentsTotalByPromoter($promoterId: String!) {
    getPendingPrePaymentsTotalByPromoter(promoterId: $promoterId) {
      deposits
      prepaymentTotal
    }
  }
`

export const APPLY_PAYMENT = gql`
  mutation ApplyPayment(
    $referenceNumber: String!
    $authorizationNumber: String!
    $promoterId: String!
    $paymentAmount: Float!
    $applicationDate: String!
    $bankType: String!
  ) {
    applyPayment(
      referenceNumber: $referenceNumber
      authorizationNumber: $authorizationNumber
      promoterId: $promoterId
      paymentAmount: $paymentAmount
      applicationDate: $applicationDate
      bankType: $bankType
    ) {
      id
      dateApplication
      referenceNumber
      amount
      bankType
      authorizationNumber
      createdBy
      status
      createdAt
      updatedAt
    }
  }
`
