import { createSlice } from '@reduxjs/toolkit'
import { Prospect } from 'types'

interface ProspectState {
  prospect: Prospect | null
}

const initialState: ProspectState = {
  prospect: null,
}

const prospectSlice = createSlice({
  name: 'prospect',
  initialState,
  reducers: {
    setProspectDataReducer: (state, action) => {
      state.prospect = action.payload
    },
  },
})

const { actions } = prospectSlice

export { actions }

export default prospectSlice.reducer
