import { useContext, useEffect, useState } from 'react'
import {
  Container,
  InfoContainer,
  TitleContainer,
  StyledTypography,
  StyledRadio,
  GenericInput,
} from './styled'
import { FormControlLabel, FormLabel, RadioGroup, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import 'dayjs/locale/es-mx'
import { APPLY_PAYMENT_ORDER, GET_ALL_BANKS } from './mutation'
import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { AlertContext } from 'hooks/AlertContext'
import { Bank, PaymentOrderStep } from 'types'
import { formatToCurrency } from 'utils'
import { theme } from 'theme/theme'
import GenericAlert from 'components/GenericAlert'

interface Props {
  applyPaymentOrderId: string
  moneyAmount: number
  clientFullName: string
  closeAddModal: () => void
  changeTab: (tab: number) => void
}

const ModalRegisterPayout = ({
  applyPaymentOrderId,
  moneyAmount,
  clientFullName,
  changeTab,
  closeAddModal,
}: Props) => {
  const [applyPaymentOrder, { loading }] = useMutation(APPLY_PAYMENT_ORDER)
  const { showSnackbar } = useContext(AlertContext)
  const [banks, setBanks] = useState<Bank[]>([])
  const [bankOption, setBankOption] = useState<string>('')
  const [referenceNumber, setReferenceNumber] = useState<string>('')
  const {
    loading: banksLoading,
    data,
    error,
  } = useQuery(GET_ALL_BANKS, {
    fetchPolicy: 'no-cache',
  })

  const handleRegisterPayout = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      await applyPaymentOrder({
        variables: { applyPaymentOrderId, referenceNumber, bankType: bankOption },
      })
      changeTab(PaymentOrderStep.DISBURSEDS)
      closeAddModal()
      showSnackbar('Desembolso aplicado')
    } catch (error) {
      showSnackbar('Ocurrió un problema. Inténtalo de nuevo más tarde.', 'error')
    }
  }

  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setBankOption(value)
  }

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    setReferenceNumber(text)
  }

  useEffect(() => {
    if (data) {
      setBanks(data?.getBanks)
    }
  }, [data])

  return (
    <Container onSubmit={handleRegisterPayout}>
      <TitleContainer>
        <Typography variant="h1">Desembolsar orden de pago</Typography>
        <Typography variant="body1" color={theme.typographyColor.primaryText}>
          Se registrará la orden de pago para que se realice el desembolso para este cliente
        </Typography>
      </TitleContainer>
      <InfoContainer>
        <Typography variant="body2">Cliente</Typography>
        <Typography variant="body1">{clientFullName}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2">Monto</Typography>
        <Typography variant="body1">{formatToCurrency(moneyAmount)} MXN</Typography>
      </InfoContainer>
      <InfoContainer>
        <FormLabel>Banco</FormLabel>
        <RadioGroup row name="radio-buttons-group" value={bankOption} onChange={handleBankChange}>
          {!banksLoading &&
            banks.length > 0 &&
            banks.map((bank) => (
              <FormControlLabel
                key={bank.id}
                control={
                  <StyledRadio
                    value={bank.enumBankName}
                    checked={bankOption === bank.enumBankName}
                  />
                }
                label={bank.bankName}
              />
            ))}
        </RadioGroup>
      </InfoContainer>
      <InfoContainer>
        <GenericInput
          value={referenceNumber}
          inputProps={{ maxLength: 15, placeholder: 'Ingresa número de referencia' }}
          label="Número de referencia"
          onChange={handleChangeText}
        />
      </InfoContainer>
      {error && (
        <InfoContainer>
          <GenericAlert
            status="error"
            title="Ocurrió un error al cargar los bancos. Inténtalo de nuevo más tarde"
          />
        </InfoContainer>
      )}
      <InfoContainer>
        <LoadingButton
          disabled={referenceNumber.length === 0 || !(bankOption.length > 0)}
          variant="contained"
          size="large"
          loading={loading}
          type="submit"
          fullWidth
        >
          <StyledTypography variant="button">
            Desembolsar <CheckCircleOutlineIcon />
          </StyledTypography>
        </LoadingButton>
      </InfoContainer>
    </Container>
  )
}

export default ModalRegisterPayout
