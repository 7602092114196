import { useEffect, useState, SyntheticEvent, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  StyledContainer,
  StyledLeftColumn,
  StyledRightColumn,
  StyledSubContainer,
  StyledTabsContainer,
} from './styled'
import { Tab, Tabs } from '@mui/material'
import ProspectData from 'components/ProspectData'
import ProspectEconomics from 'components/ProspectEconomics'
import VerticalTabs from 'components/CustomTabs'
import TopBar from 'components/TopBar'
import GenericModal from 'components/GenericModal'
import ModalAcceptProspect from 'components/ModalAcceptProspect'
import { AlertContext } from 'hooks/AlertContext'
import GenericAlert from 'components/GenericAlert'
import {
  Prospect,
  RejectedTypeEnum,
  Rejection,
  RejectionStatusEnum,
  StatusClientEnum,
  ValidationStep,
} from 'types'
import { useQuery } from '@apollo/client'
import { GET_CLIENT_DETAILS } from './queries'
import { defaultProspectValue } from './values'
import ModalNotifyRejectProspect from 'components/ModalNotifyRejectProspect'
import dayjs from 'dayjs'

const Validator = () => {
  const navigate = useNavigate()
  const [actualStep, setActualStep] = useState(0)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [nextPath, setNextPath] = useState<boolean>(false)
  const [prospect, setProspect] = useState<Prospect>(defaultProspectValue)
  const { showSnackbar } = useContext(AlertContext)
  const { id } = useParams<{ id?: string }>()
  const { loading, error, data, refetch } = useQuery(GET_CLIENT_DETAILS, {
    variables: {
      getClientsByIdId: id,
    },
  })
  const [isOpenModalReject, setIsOpenModalReject] = useState<boolean>(false)

  const closeModalReject = () => setIsOpenModalReject(false)
  const openModalReject = () => setIsOpenModalReject(true)

  const handleChangeStep = (event: SyntheticEvent, newStep: number) => {
    setActualStep(newStep)
  }

  const changePage = () => {
    setNextPath(true)
  }

  const refetchUser = () => {
    refetch()
  }

  const goBackFunction = () => {
    navigate(-1)
  }

  const openModalFunction = () => {
    setOpenModal(true)
  }

  const closeModalFunction = () => {
    if (nextPath) {
      setOpenModal(false)
      goBackFunction()
      showSnackbar('Prospecto aceptado')
    }
    setOpenModal(false)
  }

  const prospectRejectedObject = prospect?.rejections?.find(
    (obj: Rejection) => obj.status === RejectionStatusEnum.PENDING,
  )

  useEffect(() => {
    if (!loading && data) {
      setProspect(data?.getClientsById || defaultProspectValue)
    }
  }, [id, data, loading, nextPath])

  return (
    <StyledContainer>
      <TopBar
        handleClickCloseValidatorScreen={goBackFunction}
        handleClickOpenAcceptProspect={openModalFunction}
        userData={prospect?.personalData}
        handleRejectProspect={openModalReject}
        statusClient={prospect.status}
        acceptedAt={dayjs(prospect.acceptedAt).format('MMM DD, YYYY') || ''}
      />
      <StyledSubContainer>
        {prospect.status === StatusClientEnum.REJECTED && (
          <GenericAlert
            status="error"
            title={`Prospecto rechazado por verificación ${
              prospectRejectedObject?.type === RejectedTypeEnum.AUTOMATIC_VERIFICATION
                ? 'automatizada'
                : prospectRejectedObject?.type === RejectedTypeEnum.MANUAL
                ? 'manual'
                : ''
            }`}
            description={prospectRejectedObject?.reason}
            notes={
              prospectRejectedObject?.internalReason
                ? `Nota interna: ${prospectRejectedObject?.internalReason}`
                : ''
            }
          ></GenericAlert>
        )}
        <StyledTabsContainer>
          <StyledLeftColumn>
            <Tabs
              value={actualStep}
              onChange={handleChangeStep}
              textColor="secondary"
              indicatorColor="secondary"
              variant="fullWidth"
              aria-label="Prospects tabs"
            >
              <Tab label="Datos Personales" />
              <Tab label="Actividad Económica" />
            </Tabs>
            {actualStep === ValidationStep.FIRST_STEP && (
              <ProspectData
                firstName={prospect.firstName}
                firstLastName={prospect.firstLastName}
                secondLastName={prospect.secondLastName}
                contactData={prospect.contactData}
                personalData={prospect.personalData}
              />
            )}
            {actualStep === ValidationStep.SECOND_STEP && (
              <ProspectEconomics economicData={prospect.economicData} />
            )}
          </StyledLeftColumn>
          <StyledRightColumn>
            <VerticalTabs
              refetchUser={refetchUser}
              error={error}
              personalData={prospect.personalData}
              contactUser={prospect.contactData}
            />
          </StyledRightColumn>
        </StyledTabsContainer>
      </StyledSubContainer>
      <GenericModal closeModalFunction={closeModalFunction} isOpen={openModal}>
        <ModalAcceptProspect
          handleClose={closeModalFunction}
          nextPath={nextPath}
          changePage={changePage}
        />
      </GenericModal>
      <ModalNotifyRejectProspect
        isOpenModal={isOpenModalReject}
        closeModal={closeModalReject}
        selectedProspect={prospect}
        getProspectDetails={refetchUser}
      />
    </StyledContainer>
  )
}

export default Validator
