import { JSXElementConstructor, useState } from 'react'
import { Button, Typography } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  esES,
  GridSortModel,
  GridColumnMenu,
  GridColumnMenuProps,
  GridPaginationModel,
  GridSortItem,
  GridCallbackDetails,
} from '@mui/x-data-grid'
import {
  CreditByStatus,
  DisbursedCredit,
  PaymentOrder,
  PaymentType,
  PromoterStructure,
  Prospect,
} from '../../types/index'
import { DataGridContainer, StyledGridToolbarContainer, NoResultsContainer } from './styled'
import { theme } from 'theme/theme'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import GlassImage from 'assets/glass.png'
import { FilterList } from '@mui/icons-material'

const getFilter = (nameFilter?: string | { field: string; value: string; operator?: string }) => {
  if (typeof nameFilter === 'string') {
    return [{ field: 'firstName', operator: 'contains', value: nameFilter }]
  }
  if (nameFilter?.field) {
    const { field, operator, value } = nameFilter
    return [{ field, operator: operator || 'contains', value }]
  }
  return []
}

interface Props {
  loading: boolean
  data: PromoterStructure[] | Prospect[] | PaymentOrder[] | CreditByStatus[] | DisbursedCredit[]
  dataColumns: GridColDef[]
  nameFilter?: string | { field: string; value: string; operator?: string }
  title: string
  pageSizeOptions?: number[]
  initialState?: GridInitialStateCommunity
  totalRows?: number
  handleChangePage?: (objectPages: GridPaginationModel) => void
  page?: number
  pageSize?: number
  sort?: GridSortItem[]
  filterButton?: {
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
  sortingMode?: 'server'
  filterMode?: 'server' | 'client'
  paginationMode?: 'server'
  toolbar?: JSXElementConstructor<CustomToolbarProps> | null | undefined
  paymentType?: PaymentType
  filterTypeButtons?: {
    disabled?: boolean
    onClick: (type: PaymentType) => void
  }
  handleSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<never>) => void
}

export interface CustomToolbarProps {
  title: string
  paymentType?: PaymentType
  filterTypeButtons?: {
    disabled?: boolean
    onClick: (type: PaymentType) => void
  }
  filterButton?: {
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
}

function CustomToolbar({ title, filterButton }: CustomToolbarProps) {
  return (
    <StyledGridToolbarContainer>
      <Typography variant="body1" color={theme.typographyColor.primaryText}>
        {title}
      </Typography>
      {filterButton && (
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={filterButton.handleClick}
          startIcon={<FilterList />}
        >
          Filtros
        </Button>
      )}
    </StyledGridToolbarContainer>
  )
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  )
}

function CustomNoRowsOverlay() {
  return (
    <NoResultsContainer>
      <NoResultsContainer>
        <img src={GlassImage} />
        <Typography variant="h3">Sin Resultados</Typography>
        <Typography variant="body1">
          No encontramos ningún resultado basado en tu búsqueda.
        </Typography>
      </NoResultsContainer>
    </NoResultsContainer>
  )
}

const CustomDataGrid = ({
  data,
  dataColumns,
  nameFilter,
  title,
  loading,
  pageSizeOptions,
  initialState,
  totalRows,
  handleChangePage,
  page,
  pageSize,
  sort,
  filterButton,
  sortingMode,
  filterMode,
  paginationMode,
  paymentType,
  filterTypeButtons,
  toolbar,
  handleSortModelChange,
}: Props) => {
  const [sortModel, setSortModel] = useState<GridSortModel>(
    sort || [
      {
        field: 'createdAt',
        sort: 'desc',
      },
    ],
  )
  return (
    <DataGridContainer>
      <DataGrid
        {...{
          sortingMode,
          filterMode,
          paginationMode,
        }}
        filterModel={{
          items: getFilter(nameFilter),
        }}
        rows={data}
        columns={dataColumns}
        disableRowSelectionOnClick
        initialState={
          initialState || {
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }
        }
        pageSizeOptions={
          totalRows && pageSizeOptions && totalRows < pageSizeOptions[0]
            ? [10]
            : pageSizeOptions || [10, 25, 30]
        }
        sortModel={sortModel}
        slots={{
          columnMenu: CustomColumnMenu,
          toolbar: toolbar || CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          panel: {
            placement: 'bottom-end',
          },
          toolbar: {
            title,
            filterButton,
            paymentType,
            filterTypeButtons,
          },
        }}
        onSortModelChange={(newSortModel, details) => {
          if (handleSortModelChange) {
            handleSortModelChange(newSortModel, details as GridCallbackDetails<never>)
          }
          setSortModel(newSortModel)
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        loading={loading}
        rowCount={totalRows}
        onPaginationModelChange={handleChangePage}
        paginationModel={
          typeof page === 'number' && pageSize
            ? {
                page,
                pageSize,
              }
            : undefined
        }
      />
    </DataGridContainer>
  )
}

export default CustomDataGrid
