import React from 'react'
import { InfoContainer, StyledContainer } from './styled'
import { Divider, Typography } from '@mui/material'
import { theme } from 'theme/theme'
import { Income, IncomePeriodicityEnum } from 'types'

interface Props {
  income: Income
  index: number
}

const AditionalIncomes = ({ income, index }: Props) => {
  const { amount, description, periodicity } = income || {}

  const formatCurrencyMXN = (amount: number): string => {
    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    return formatter.format(amount)
  }

  const translateIncomePeriodicity = (periodicity: string) => {
    switch (periodicity) {
      case IncomePeriodicityEnum.WEEKLY:
        return 'Semanal'
      case IncomePeriodicityEnum.BIWEEKLY:
        return 'Quincenal'
      case IncomePeriodicityEnum.MONTHLY:
        return 'Mensual'
      default:
        return 'Desconocido'
    }
  }

  return (
    <StyledContainer>
      <Typography variant="button" color={theme.typographyColor.accentColor}>
        {`Ingreso adicional #${index + 1}`}
      </Typography>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Nombre del ingreso
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Ingresos
        </Typography>
        <Typography variant="body1">{formatCurrencyMXN(amount)}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Periodicidad
        </Typography>
        <Typography variant="body1">{translateIncomePeriodicity(periodicity)}</Typography>
      </InfoContainer>
      <Divider></Divider>
    </StyledContainer>
  )
}

export default AditionalIncomes
