import { gql } from '@apollo/client'

export const APPLY_PAYMENT_ORDER = gql`
  mutation ApplyPaymentOrder(
    $applyPaymentOrderId: String!
    $referenceNumber: String!
    $bankType: String!
  ) {
    applyPaymentOrder(
      id: $applyPaymentOrderId
      referenceNumber: $referenceNumber
      bankType: $bankType
    ) {
      id
      clientFullName
      clientPhoneNumber
      clientPhoneLada
      promoterFullName
      amount
      status
      referenceNumber
      disbursementDate
      appliedDate
      createdAt
      updatedAt
    }
  }
`

export const GET_ALL_BANKS = gql`
  query GetBanks {
    getBanks {
      id
      bankName
      beneficiaryName
      accountNumber
      enumBankName
      createdAt
      updatedAt
    }
  }
`
