import styled from '@emotion/styled/macro'
import { Button, FormControlLabel, Radio } from '@mui/material'
import { GenericInput } from 'components/common/styled'
import { theme } from 'theme/theme'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
`
export const BlueContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  background-color: ${theme.extraBackgrounds.activeToggleButton};
`
export const StyledData = styled.div`
  font-size: 18px;
  font-style: bold;
  line-height: normal;
`
export const StyledGenericInput = styled(GenericInput)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const StyledForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`
export const StyledRadio = styled(Radio)`
  color: black;
  &.Mui-checked {
    color: green;
  }
`
export const StyledButton = styled(Button)`
  padding: 12px 20px;
  background: ${theme.extraBackgrounds.selectedItem};
  color: black;
  font-style: bold;
  font-size: 18px;
  border-radius: 8px;
`
export const StyledFormControl = styled(FormControlLabel)`
  border-bottom: 1px solid #eee;
`
export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
export const StyledError = styled.div`
  color: #9e1c1c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 16px;
`
