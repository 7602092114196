import styled from '@emotion/styled'
import { Alert } from '@mui/material'
import { theme } from 'theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledAlert = styled(Alert)`
  width: 100%;
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
