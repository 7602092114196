import { Container, InfoContainer, TitleContainer, StyledAlert, StyledTypography } from './styled'
import { Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useMutation } from '@apollo/client'
import { REMOVE_PAYOUT } from './mutation'
import { LoadingButton } from '@mui/lab'
import dayjs from 'dayjs'
import { AlertContext } from 'hooks/AlertContext'
import { useContext } from 'react'
import { PaymentOrderStep } from 'types'
import { formatToCurrency } from 'utils'

interface Props {
  clientFullName: string
  moneyAmount: number
  payoutDate: string
  payoutID: string
  closeEditModal: () => void
  changeTab: (tab: number) => void
}

const ModalEditPayout = ({
  clientFullName,
  moneyAmount,
  payoutDate,
  payoutID,
  closeEditModal,
  changeTab,
}: Props) => {
  const [removePaymentOrder, { loading }] = useMutation(REMOVE_PAYOUT)
  const { showSnackbar } = useContext(AlertContext)
  const handleEditPayout = async () => {
    try {
      await removePaymentOrder({
        variables: { removeDisbursementId: payoutID },
      })
      closeEditModal()
      changeTab(PaymentOrderStep.APPLIEDS)
      showSnackbar('Orden de pago deshecha')
    } catch (error) {
      //TODO handle error
    }
  }

  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const formatDate = (dateString: string): string => {
    dayjs.locale('es')
    const dayOfWeek = dayjs(dateString).format('dddd')
    const dayOfCalendar = dayjs(dateString).format('D')
    const month = dayjs(dateString).format('MMMM')
    const year = dayjs(dateString).format('YYYY')

    return `${capitalizeFirstLetter(dayOfWeek)} ${dayOfCalendar} de ${capitalizeFirstLetter(
      month,
    )}, ${year}`
  }

  return (
    <Container>
      <TitleContainer>
        <Typography variant="h1">Editar desembolso</Typography>
        <Typography variant="body1">Se regresará esta solicitud a estatus: Aplicado</Typography>
        <StyledAlert icon={<InfoOutlinedIcon />} severity="warning">
          Se notificará a la promotora y al cliente que el monto del crédito desembolsado ha sido
          deshecho
        </StyledAlert>
      </TitleContainer>
      <InfoContainer>
        <Typography variant="body2">Cliente</Typography>
        <Typography variant="body1">{clientFullName}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2">Monto</Typography>
        <Typography variant="body1">{formatToCurrency(moneyAmount)} MXN</Typography>
      </InfoContainer>
      <InfoContainer>
        <StyledAlert icon={false} severity="warning">
          <Typography variant="body2">Fecha de desembolso</Typography>
          <del>{formatDate(payoutDate)}</del>
        </StyledAlert>
      </InfoContainer>
      <InfoContainer>
        <LoadingButton
          variant="outlined"
          color="error"
          size="large"
          loading={loading}
          onClick={handleEditPayout}
          fullWidth
        >
          <StyledTypography variant="button">
            Remover Desembolso <CheckCircleOutlineIcon />
          </StyledTypography>
        </LoadingButton>
      </InfoContainer>
    </Container>
  )
}

export default ModalEditPayout
