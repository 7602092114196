import { FC, useState } from 'react'
import { BottomContent, TopContent } from '../styled'
import {
  ContainerButtons,
  ContainerInfoPromoter,
  ContainerInput,
  ContainerName,
  ContainerOptions,
  StyledAlert,
  StyledFormHelperText,
  StyledTextField,
} from './styled'
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import { theme } from 'theme/theme'
import { Prospect, RejectedTypeEnum, StatusClientEnum } from 'types'
import { useMutation } from '@apollo/client'
import { REJECT_PROSPECT } from '../queries'

type Props = {
  selectedProspect: Prospect
  handleChangeStep: (step: number) => void
  titleAndLabel: string
}

const NEXT_STEP = 2

const RejectNotify: FC<Props> = ({ selectedProspect, handleChangeStep, titleAndLabel }) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const fullName = `${selectedProspect?.firstName} ${selectedProspect?.firstLastName} ${selectedProspect?.secondLastName}`
  const [rejectProspect, { loading }] = useMutation(REJECT_PROSPECT)
  const [rejectedReason, setRejectedReason] = useState<string>(
    selectedProspect.status === StatusClientEnum.REJECTED
      ? selectedProspect?.rejections?.[0]?.reason ?? ''
      : '',
  )
  const [rejectedInternalNote, setRejectedInternalNote] = useState<string>(
    selectedProspect.status === StatusClientEnum.REJECTED
      ? selectedProspect?.rejections?.[0]?.internalReason ?? ''
      : '',
  )
  const [inputError, setInputError] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState({
    personalData: false,
    economicData: false,
    contactData: false,
  })

  const handleRejectProspect = async () => {
    if (!rejectedReason) {
      setInputError(true)
      return
    }
    setInputError(false)

    try {
      const { data } = await rejectProspect({
        variables: {
          rejectClientId: selectedProspect.id,
          rejectClientInfo: {
            reason: rejectedReason,
            internalReason: rejectedInternalNote,
            contactData: checkedItems.contactData,
            economicData: checkedItems.economicData,
            personalData: checkedItems.personalData,
            type: RejectedTypeEnum.MANUAL,
          },
        },
      })
      if (data?.rejectClient) {
        handleChangeStep(NEXT_STEP)
      } else {
        setHasError(true)
      }
    } catch (error) {
      setHasError(true)
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    })
  }

  return (
    <>
      <TopContent>
        <Typography variant="h1" color={theme.typographyColor.primaryText}>
          {titleAndLabel}
        </Typography>
        <Typography variant="body1" color={theme.typographyColor.primaryText}>
          Se le notificará a la promotora y deberá gestionar una nueva solicitud con los cambios
          requeridos
        </Typography>
      </TopContent>
      <BottomContent>
        <ContainerInfoPromoter>
          <ContainerName>
            <Typography variant="body2" color={theme.typographyColor.secondaryText}>
              Prospecto
            </Typography>
            <Typography variant="body1" color={theme.typographyColor.primaryText}>
              {fullName}
            </Typography>
          </ContainerName>
          <ContainerOptions>
            <ContainerInput>
              <StyledTextField
                color="info"
                type="text"
                name="rejectedReason"
                label="Motivo del rechazo"
                multiline
                minRows={4}
                fullWidth
                value={rejectedReason}
                onChange={(e) => setRejectedReason(e.target.value)}
                error={inputError}
              />
              <StyledFormHelperText>
                Se le notificará a la promotora el motivo del rechazo
              </StyledFormHelperText>
            </ContainerInput>
            <ContainerInput>
              <StyledTextField
                color="info"
                type="text"
                name="rejectedInternalNote"
                label="Nota interna (Optional)"
                multiline
                minRows={4}
                fullWidth
                value={rejectedInternalNote}
                onChange={(e) => setRejectedInternalNote(e.target.value)}
              />
              <StyledFormHelperText>
                La nota interna solo es visible para los empleados
              </StyledFormHelperText>
            </ContainerInput>
            <ContainerInput>
              <Typography variant="button">Selecciona los módulos que necesitan cambios</Typography>
              <FormGroup>
                <FormControlLabel
                  label="Datos personales"
                  control={
                    <Checkbox
                      checked={checkedItems.personalData}
                      onChange={handleCheckboxChange}
                      name="personalData"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                    />
                  }
                />
                <FormControlLabel
                  label="Actividad Económica"
                  control={
                    <Checkbox
                      checked={checkedItems.economicData}
                      onChange={handleCheckboxChange}
                      name="economicData"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                    />
                  }
                />
                <FormControlLabel
                  label="Datos de contacto"
                  control={
                    <Checkbox
                      checked={checkedItems.contactData}
                      onChange={handleCheckboxChange}
                      name="contactData"
                      icon={<CheckBoxOutlineBlankOutlinedIcon />}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                    />
                  }
                />
              </FormGroup>
            </ContainerInput>
          </ContainerOptions>
        </ContainerInfoPromoter>
        {hasError && (
          <StyledAlert severity="error">
            <Typography variant="alertText">
              Parece que algo salio mal. Inténtalo de nuevo.
            </Typography>
          </StyledAlert>
        )}
        <ContainerButtons>
          <LoadingButton
            variant="contained"
            disableElevation
            onClick={handleRejectProspect}
            loading={loading}
            loadingPosition="center"
            fullWidth
          >
            {titleAndLabel}
          </LoadingButton>
        </ContainerButtons>
      </BottomContent>
    </>
  )
}

export default RejectNotify
