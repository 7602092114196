import styled from '@emotion/styled'
import { Alert, Button } from '@mui/material'
import { theme } from 'theme/theme'

type StyleProps = {
  backgroundColor?: string
  padding?: string
  gap?: string
}

interface StyledContainerProps {
  marginTop?: string
}

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 72px 48px;
  display: flex;
  flex-direction: row;
  gap: 64px;
  box-sizing: border-box;
  background-color: #fbfbfb;
  justify-content: center;
  align-items: center;
`

export const Column = styled.div<StyleProps>`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#fbfbfb')};
  border-radius: 16px;
  padding: ${({ padding }) => (padding ? padding : '0px')};
`

export const ImageContainer = styled.img<StyleProps>`
  object-fit: contain;
  max-height: 90%;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`

export const FormSection = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ gap }) => (gap ? gap : '0')};
  align-self: stretch;
  width: 100%;
`

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 16px;
`

export const LoginFormContainer = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  height: 100%;
  gap: 40px;
  padding: 0px 48px;
`

export const RecoverPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`

export const ErrorMessage = styled.div`
  color: var(--typography-on-error-background, #9e1c1c);
  font-family: ${theme.typography.fontFamily};
  padding: 0px 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const StyledAlert = styled(Alert)`
  width: 100%;
  font-family: ${theme.typography.fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const StyledLink = styled(Button)`
  color: ${theme.typographyColor.accentColor};
  padding: 0;
  letter-spacing: 0.16px;
  text-transform: none;
`

export const ArrowBackIconButton = styled(Button)`
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.primaryText};
  }
  padding: 0;
  text-transform: none;
`

export const StyledContainer = styled.div<StyledContainerProps>`
  margin-top: ${({ marginTop }) => marginTop};
  width: 100%;
`
