import { FunctionComponent, useRef } from 'react'
import { Container, StyledTextInput, StyledButton } from './styled'
import { HighlightOffOutlined, Search } from '@mui/icons-material'

interface Props {
  text: string
  handleChangeText: (text: string) => void
  placeholder: string
  width?: number
  disabled?: boolean
}

const SearchInput: FunctionComponent<Props> = ({
  text,
  handleChangeText,
  placeholder,
  width,
  disabled,
}: Props) => {
  const refInput = useRef<HTMLInputElement | null>(null)

  const handleFocusTextInput = () => refInput?.current?.focus()

  const handleCleanInput = () => handleChangeText('')

  return (
    <Container width={width}>
      <StyledButton onClick={handleFocusTextInput} isVisible>
        <Search />
      </StyledButton>
      <StyledTextInput
        ref={refInput}
        disabled={disabled}
        value={text}
        placeholder={placeholder}
        onChange={(event) => handleChangeText(event.target.value)}
      />
      <StyledButton
        onClick={handleCleanInput}
        cursorPointer
        isVisible={text.length > 0 ? true : false}
      >
        <HighlightOffOutlined />
      </StyledButton>
    </Container>
  )
}

export default SearchInput
