import styled from '@emotion/styled'
import { TextField } from '@mui/material'
import { theme } from 'theme/theme'
import { Box } from '@mui/material'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const GenericInput = styled(TextField)`
  width: 100%;
  color: ${theme.typographyColor.secondaryText};
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.iconBasicColor};
  }
`

interface EmptyContainerProps {
  paddingVertical: number
}

export const EmptyContainer = styled.div<EmptyContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ paddingVertical }) => paddingVertical}px 0px;
  background-color: ${theme.extraBackgrounds.paper01};
  border-radius: 16px;
`

export const StyledBox = styled(Box)`
  border-bottom: 1px;
  border-color: ${theme.palette.divider};
  margin-top: 40px;
  margin-bottom: 24px;
`
