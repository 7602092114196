import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import { theme } from 'theme/theme'

export const StyledIconButton = styled(IconButton)`
  padding: 0px;
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.iconBasicColor};
  }
`
