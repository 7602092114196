import styled from '@emotion/styled'
import { Chip } from '@mui/material'
import { theme } from 'theme/theme'
import { StatusClientEnum } from 'types'

type StyledChipProps = {
  state: StatusClientEnum
}

export const StyledChip = styled(Chip)<StyledChipProps>`
  font-family: DMSansRegular;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
  background-color: ${({ state }) =>
    state === StatusClientEnum.PENDING
      ? theme.extraBackgrounds.activeChip
      : state === StatusClientEnum.PREREJECTED && theme.extraBackgrounds.inactiveChip};
  color: ${({ state }) =>
    state === StatusClientEnum.PENDING
      ? theme.typographyColor.onSuccess
      : state === StatusClientEnum.PREREJECTED && theme.typographyColor.errorText};
  padding: 4px
    ${({ state }) =>
      state === StatusClientEnum.PENDING
        ? '16px'
        : state === StatusClientEnum.PREREJECTED && '12px'};
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: ${({ state }) =>
      state === StatusClientEnum.PENDING
        ? theme.typographyColor.onSuccess
        : state === StatusClientEnum.PREREJECTED && theme.typographyColor.errorText};
  }
`
