import CustomDataGrid from 'components/CustomDataGrid'
import { PaymentOrder, PaymentOrderStatus, PaymentSortModel } from 'types'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { columnsAccepted } from '../Columns'
import { OPTIONS_ROWS_PER_PAGE, ORDER, ORDER_BY_CREATED_AT } from '..'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { GET_PAYMENT_ORDERS } from '../queries'
import { DateRange } from 'react-day-picker'
import GenericModal from 'components/GenericModal'
import ModalRegisterPayout from 'components/ModalRegisterPayout'
import { NetworkError } from 'components/CustomDataGrid/EmptyStates/NetworkError'
import { RequestError } from 'components/CustomDataGrid/EmptyStates/RequestError'
import { NoData } from 'components/CustomDataGrid/EmptyStates/NoData'

interface Props {
  dateRange: DateRange | undefined
  inputFilter: string
  changeTab: (tab: number) => void
  handleFilterButton: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Accepted = ({ inputFilter, dateRange, changeTab, handleFilterButton }: Props) => {
  const [acceptedPayments, setAcceptedPayments] = useState<PaymentOrder[]>([])
  const [totalPayments, setTotalPayments] = useState<number>(0)
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(OPTIONS_ROWS_PER_PAGE[0])
  const initialStateGrid: GridInitialStateCommunity = {
    pagination: {
      paginationModel: {
        page: page,
        pageSize: rowsPerPage,
      },
    },
  }
  const sortModel: GridSortModel = [
    {
      field: ORDER_BY_CREATED_AT,
      sort: ORDER,
    },
  ]
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)
  const [disburseData, setDisburseData] = useState<{
    amount: number
    id: string
    clientFullName: string
  } | null>(null)

  const { loading, data, error, refetch } = useQuery(GET_PAYMENT_ORDERS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: page,
      limit: rowsPerPage,
      order: ORDER,
      orderBy: ORDER_BY_CREATED_AT,
      filters: {
        statuses: PaymentOrderStatus.PENDING,
        search: filterSearch,
        datesFilter: {
          dates: {
            startDate: dateRange?.from?.toISOString(),
            endDate: dateRange?.to?.toISOString(),
          },
          filterField: PaymentSortModel.CREATION,
        },
      },
    },
  })

  const handleChangePage = (objectPages: GridPaginationModel) => {
    setPage(objectPages.page)
    setRowsPerPage(objectPages.pageSize)
  }

  const handleErrorButton = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      refetch()
    }
  }

  const handleClickDisburseButton = (data: {
    amount: number
    id: string
    clientFullName: string
  }) => {
    setDisburseData(data)
  }

  const handleCloseModal = () => {
    setDisburseData(null)
  }

  useEffect(() => {
    if (!loading && data) {
      setAcceptedPayments(data.getPaymentOrders.paymentOrders)
      setTotalPayments(data.getPaymentOrders.total)
    }
  }, [data, loading, dateRange])

  useEffect(() => {
    setPage(0)
    setFilterSearch(inputFilter)
  }, [inputFilter])

  const hasNoData =
    acceptedPayments.length === 0 && filterSearch.length === 0 && !dateRange && !loading

  if (!isOnlineState) return <NetworkError handleRetry={handleErrorButton} />
  if (error) return <RequestError handleRetry={handleErrorButton} />
  if (hasNoData) return <NoData message="No hay órdenes de pago aceptadas" />

  return (
    <>
      <CustomDataGrid
        title="Listado de créditos aplicados"
        loading={loading}
        data={acceptedPayments}
        dataColumns={columnsAccepted({ handleClickDisburseButton })}
        initialState={initialStateGrid}
        pageSizeOptions={OPTIONS_ROWS_PER_PAGE}
        handleChangePage={handleChangePage}
        sort={sortModel}
        page={page}
        pageSize={rowsPerPage}
        totalRows={totalPayments}
        filterButton={{ handleClick: handleFilterButton }}
      />
      {disburseData && (
        <GenericModal isOpen={Boolean(disburseData)} closeModalFunction={handleCloseModal}>
          <ModalRegisterPayout
            moneyAmount={disburseData.amount}
            changeTab={changeTab}
            applyPaymentOrderId={disburseData.id}
            clientFullName={disburseData.clientFullName}
            closeAddModal={handleCloseModal}
          />
        </GenericModal>
      )}
    </>
  )
}

export default Accepted
