import Router from 'router'
import { CssBaseline, ThemeProvider } from '@mui/material'
import AlertContext from 'hooks/AlertContext'
import { theme } from 'theme/theme'
import { Amplify } from 'aws-amplify'
import awsmobile from 'aws-exports'

Amplify.configure(awsmobile)

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AlertContext>
          <CssBaseline />
          <Router />
        </AlertContext>
      </ThemeProvider>
    </div>
  )
}

export default App
