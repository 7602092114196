import AlertTitle from '@mui/material/AlertTitle'
import { Alert, Typography } from '@mui/material'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import { Container, StyledChip } from './styled'
import { ReactNode } from 'react'

interface Props {
  status: 'success' | 'error'
  title: string
  description?: string
  notes?: string
  children?: ReactNode
}

const GenericAlert = ({ status, notes, title, description, children }: Props) => {
  return (
    <div>
      {status === 'error' && (
        <Alert icon={false} severity={status}>
          <AlertTitle>
            <Typography variant="button">{title}</Typography>
          </AlertTitle>

          {description && <Typography variant="body2">{description}</Typography>}
          {notes && (
            <Container severity={status}>
              <SubdirectoryArrowRightIcon />
              <Typography variant="body2">{notes}</Typography>
            </Container>
          )}
          {children && children}
        </Alert>
      )}
      {status === 'success' && <StyledChip icon={<VerifiedOutlinedIcon />} label={title} />}
    </div>
  )
}

export default GenericAlert
