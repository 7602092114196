import { GridToolbarContainer } from '@mui/x-data-grid'
import styled from '@emotion/styled'

export const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 16px;
  align-items: center;
`

export const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  padding: 16px 24px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
`
