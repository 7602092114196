import React from 'react'
import { AllContainer, MenuContainer, StyledIcon, TitleContainer, datePickerCss } from './styled'
import { Checkbox, IconButton, Typography } from '@mui/material'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { DateRange, DayPicker } from 'react-day-picker'
import { formatCaption } from 'utils'
import { es } from 'date-fns/locale'

interface Props {
  handleGoBackMenu: () => void
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  showAllDates: boolean
  range: DateRange | undefined
  handleDateChange: (data: DateRange | undefined) => void
}

const CalendarMenu = ({
  handleGoBackMenu,
  handleCheckboxChange,
  showAllDates,
  range,
  handleDateChange,
}: Props) => {
  return (
    <div>
      <TitleContainer>
        <IconButton onClick={handleGoBackMenu}>
          <StyledIcon />
        </IconButton>{' '}
        <Typography variant="body1">Fecha</Typography>
      </TitleContainer>
      <AllContainer>
        <Typography variant="button">Todos</Typography>
        <Checkbox
          onChange={handleCheckboxChange}
          checked={showAllDates}
          checkedIcon={<CheckBoxOutlinedIcon />}
        />
      </AllContainer>
      <MenuContainer>
        <style>{datePickerCss}</style>
        <DayPicker
          selected={range}
          onSelect={handleDateChange}
          toDate={new Date()}
          weekStartsOn={0}
          showOutsideDays
          formatters={{ formatCaption }}
          locale={es}
          id="test"
          mode="range"
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today',
          }}
          modifiersStyles={{
            disabled: { fontSize: '75%' },
          }}
        />
      </MenuContainer>
    </div>
  )
}

export default CalendarMenu
