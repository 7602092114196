import {
  StyledHeader,
  StyledNameContainer,
  StyledIconButton,
  StyledActionsContainer,
  RejectionIconButton,
  AcceptedIconButton,
} from './styled'
import { Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface Props {
  handleClickCloseValidatorScreen: () => void
  handleClickOpenAcceptProspect: () => void
  handleRejectProspect: () => void
  clientName?: string
}

const TopBar = ({
  handleClickCloseValidatorScreen,
  handleClickOpenAcceptProspect,
  handleRejectProspect,
  clientName,
}: Props) => {
  return (
    <StyledHeader>
      <StyledNameContainer>
        <StyledIconButton onClick={handleClickCloseValidatorScreen}>
          <Close />
        </StyledIconButton>
        <Typography variant="h3">{clientName}</Typography>
      </StyledNameContainer>
      <StyledActionsContainer>
        <RejectionIconButton
          startIcon={<HighlightOffIcon />}
          variant="outlined"
          onClick={handleRejectProspect}
        >
          <Typography variant="body1">Rechazar orden de pago</Typography>
        </RejectionIconButton>
        <AcceptedIconButton
          startIcon={<CheckCircleOutlineIcon />}
          variant="outlined"
          onClick={handleClickOpenAcceptProspect}
        >
          <Typography variant="body1">Aceptar orden de pago</Typography>
        </AcceptedIconButton>
      </StyledActionsContainer>
    </StyledHeader>
  )
}

export default TopBar
