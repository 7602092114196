import styled from '@emotion/styled'
import { Button } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const StyledButton = styled(Button)`
  width: 100%;
`
