import CustomDataGrid from 'components/CustomDataGrid'
import { useEffect, useState } from 'react'
import {
  CreditStatus,
  DisbursedCredit,
  PaymentOrderStatus,
  PaymentSortModel,
  PaymentType,
} from 'types'
import { useLazyQuery } from '@apollo/client'
import { columnsDisbursed } from '../Columns'
import { GET_CREDIT_BY_STATUS, GET_PAYMENT_ORDERS } from '../queries'
import { OPTIONS_ROWS_PER_PAGE, ORDER, ORDER_BY_CREATED_AT } from '..'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { DateRange } from 'react-day-picker'
import { NetworkError } from 'components/CustomDataGrid/EmptyStates/NetworkError'
import { RequestError } from 'components/CustomDataGrid/EmptyStates/RequestError'
import { NoData } from 'components/CustomDataGrid/EmptyStates/NoData'
import { CustomToolbar } from './CustomToolbar'

interface Props {
  sortDateModel: string | null
  dateRange: DateRange | undefined
  inputFilter: string
  totalItems?: number
  paymentType: PaymentType
  onChangeInputFilter: (value: string) => void
  onChangePaymentType: (type: PaymentType) => void
  changeTab: (tab: number) => void
  handleFilterButton: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Disbursed = ({
  inputFilter,
  dateRange,
  sortDateModel,
  totalItems,
  paymentType,
  onChangeInputFilter,
  onChangePaymentType,
  changeTab,
  handleFilterButton,
}: Props) => {
  const [disbursedPayments, setDisbursedPayments] = useState<DisbursedCredit[]>([])
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [totalPayments, setTotalPayments] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(OPTIONS_ROWS_PER_PAGE[0])
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)
  const initialStateGrid: GridInitialStateCommunity = {
    pagination: {
      paginationModel: {
        page: page,
        pageSize: rowsPerPage,
      },
    },
  }
  const sortModel: GridSortModel = [
    {
      field: ORDER_BY_CREATED_AT,
      sort: ORDER,
    },
  ]

  const [
    fetchPaymentOrders,
    { loading: loadingPaymentOrders, error: errorPaymentOrders, refetch: refetchGetPaymentOrders },
  ] = useLazyQuery(GET_PAYMENT_ORDERS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: page,
      limit: rowsPerPage,
      order: ORDER,
      orderBy: ORDER_BY_CREATED_AT,
      filters: {
        statuses: PaymentOrderStatus.APPLIED,
        search: filterSearch,
        datesFilter: {
          dates: {
            startDate: dateRange?.from?.toISOString(),
            endDate: dateRange?.to?.toISOString(),
          },
          filterField: sortDateModel,
        },
      },
    },
  })

  const [
    fetchSpeiCredits,
    { loading: loadingCredits, error: errorCredits, refetch: refetchGetCredits },
  ] = useLazyQuery(GET_CREDIT_BY_STATUS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: page,
      limit: rowsPerPage,
      order: ORDER,
      orderBy: ORDER_BY_CREATED_AT,
      filters: {
        statuses: CreditStatus.ACTIVE,
        paymentType: PaymentType.SPEI,
        clientName: filterSearch,
        datesFilter: {
          dates: {
            startDate: dateRange?.from?.toISOString(),
            endDate: dateRange?.to?.toISOString(),
          },
          filterField: PaymentSortModel.CREATION,
        },
      },
    },
  })

  const hasNoData =
    !totalItems &&
    filterSearch.length === 0 &&
    !dateRange &&
    !loadingPaymentOrders &&
    !loadingCredits

  const handleRefetch = () => {
    refetchGetPaymentOrders()
    refetchGetCredits()
  }

  const handleChangePage = (objectPages: GridPaginationModel) => {
    setPage(objectPages.page)
    setRowsPerPage(objectPages.pageSize)
  }

  const handleErrorButton = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      handleRefetch()
    }
  }

  useEffect(() => {
    setPage(0)
    setFilterSearch(inputFilter)
  }, [inputFilter])

  useEffect(() => {
    const getData = async () => {
      try {
        const isSpei = paymentType === PaymentType.SPEI
        const response = isSpei ? await fetchSpeiCredits() : await fetchPaymentOrders()
        if (response?.data) {
          const data = isSpei
            ? response.data.getCreditsByStatus
            : response.data.getPaymentOrders.paymentOrders
          setDisbursedPayments(data)
          setTotalPayments(data.length)
        } else {
          throw new Error('Error al obtener las órdenes de pago desembolsadas')
        }
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [paymentType, page, rowsPerPage, filterSearch, dateRange])

  if (!isOnlineState) return <NetworkError handleRetry={handleErrorButton} />
  if (errorPaymentOrders && errorCredits) return <RequestError handleRetry={handleErrorButton} />
  if (hasNoData) return <NoData message="No hay órdenes de pago desembolsadas" />

  return (
    <CustomDataGrid
      title="Listado de créditos desembolsados"
      loading={loadingPaymentOrders || loadingCredits}
      data={disbursedPayments}
      dataColumns={columnsDisbursed(handleRefetch, changeTab)}
      initialState={initialStateGrid}
      pageSizeOptions={OPTIONS_ROWS_PER_PAGE}
      handleChangePage={handleChangePage}
      sort={sortModel}
      page={page}
      pageSize={rowsPerPage}
      totalRows={filterSearch.length > 3 || dateRange ? totalPayments : totalItems}
      paymentType={paymentType}
      filterTypeButtons={{
        disabled: loadingCredits || loadingPaymentOrders,
        onClick: (newPaymentType) => {
          onChangePaymentType(newPaymentType)
          setPage(0)
          onChangeInputFilter('')
          setFilterSearch('')
          setTotalPayments(0)
        },
      }}
      filterButton={{ handleClick: handleFilterButton }}
      toolbar={CustomToolbar}
    />
  )
}

export default Disbursed
