import styled from '@emotion/styled'
import { Button, ToggleButton } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
`
export const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  overflow-y: auto;
  max-height: 80vh;
`

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 80vh;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 24px;
  border: 1px solid var(--system-colors-stroke-01, #eee);
  background: var(--backgrounds-paper-03, #fff);
`

export const DocumentImg = styled.img`
  max-width: 70%;
  height: 100%;
  object-fit: contain;
`

export const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  flex-direction: column;
  min-width: 7rem;
  min-height: 8.625rem;
`

export const StyledIconContainer = styled.img`
  border: 1px solid var(--system-colors-stroke-01, #eee);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px;
  border-bottom: none;
`

export const StyledButton = styled(Button)`
  && {
    background-color: #2196f3; /* Default background color */
    color: #fff; /* Default text color */
    transition: background-color 0.3s ease; /* Add transition for smooth effect */

    &:hover {
      background-color: #1976d2; /* Change background color on hover */
    }

    &:active {
      background-color: #0d47a1; /* Change background color on active */
    }
  }
`
export const TextContainer = styled.div`
  max-width: 60px;
  word-wrap: break-word;
  display: inline-block;
`
