import { useContext, useMemo, FunctionComponent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Divider,
  StyledContainer,
  StyledLeftColumn,
  StyledRightColumn,
  StyledSubContainer,
  StyledTabsContainer,
} from './styled'
import TopBar from './TopBar'
import { AlertContext } from 'hooks/AlertContext'
import DocumentsSelectorViewer from './DocumentsSelectorViewer'
import { DocumentsTypes } from './types'
import ClientInformation from './ClientInformation'
import CreditInformation from './CreditInformation'
import { useData } from './Hooks/Data'
import { getFullName } from 'utils'
import { ModalAcceptOperation } from '../../components/ModalAcceptOperation'
import { ModalRejectOperation } from '../../components/ModalRejectOperation'

const CreditsValidator: FunctionComponent = () => {
  const navigate = useNavigate()
  const { showSnackbar } = useContext(AlertContext)
  const { id } = useParams<{ id?: string }>()
  const {
    credit,
    client,
    promoterName,
    promoterBalance,
    errorClientData,
    errorCreditData,
    errorPromoterData,
    refetchClient,
    refetchCredit,
    refetchPromoter,
  } = useData({ id })

  const clientFullName = getFullName(client?.personalData)
  const frontINEUrl = client.personalData?.frontINEUrl || ''
  const backINEUrl = client.personalData?.backINEUrl || ''
  const videoUrl = credit.videoUrl || ''
  const legalPDFUrl = credit.legalPDF || ''
  const [acceptModal, setAcceptModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)

  const options = useMemo(
    () => [
      { type: DocumentsTypes.INE_FRONT, url: frontINEUrl },
      { type: DocumentsTypes.INE_BACK, url: backINEUrl },
      { type: DocumentsTypes.ACCEPTANCE_VIDEO, url: videoUrl },
      { type: DocumentsTypes.LEGAL_PDF, url: legalPDFUrl },
    ],
    [frontINEUrl, backINEUrl, videoUrl, legalPDFUrl],
  )

  const goBackFunction = () => {
    navigate(-1)
  }

  const handleOpenAcceptModal = () => setAcceptModal(true)
  const handleCloseAcceptModal = () => setAcceptModal(false)
  const handleOpenRejectModal = () => setRejectModal(true)
  const handleCloseRejectModal = () => setRejectModal(false)
  const handleSuccessReject = () => {
    setRejectModal(false)
    navigate(-1)
    showSnackbar('Orden de pago rechazada', 'success')
  }
  const handleSuccessAccept = () => {
    setAcceptModal(false)
    navigate(-1)
    showSnackbar('Orden de pago aceptada', 'success')
  }

  const refetchData = () => {
    refetchClient({ getClientsByIdId: credit?.clientId })
    refetchCredit({ creditId: id })
    refetchPromoter({ getPromoterById: credit?.createdAt })
  }

  return (
    <StyledContainer>
      <TopBar
        handleClickCloseValidatorScreen={goBackFunction}
        handleClickOpenAcceptProspect={() => {
          handleOpenAcceptModal()
        }}
        clientName={clientFullName}
        handleRejectProspect={() => {
          handleOpenRejectModal()
        }}
      />
      <StyledSubContainer>
        <StyledTabsContainer>
          <StyledLeftColumn>
            <CreditInformation {...{ credit, promoterName }} />
            <Divider />
            <ClientInformation {...{ client }} />
          </StyledLeftColumn>
          <StyledRightColumn>
            <DocumentsSelectorViewer
              creditId={credit.id}
              options={options}
              error={errorClientData || errorCreditData || errorPromoterData}
              refetch={refetchData}
            />
          </StyledRightColumn>
        </StyledTabsContainer>
      </StyledSubContainer>
      <ModalAcceptOperation
        paymentType={credit.paymentType}
        creditId={credit.id}
        clientName={clientFullName}
        amount={credit.amount}
        isOpen={acceptModal}
        handleClose={handleCloseAcceptModal}
        onSuccess={handleSuccessAccept}
        promoterName={promoterName}
        promoterBalance={promoterBalance}
      />
      <ModalRejectOperation
        paymentType={credit.paymentType}
        creditId={credit.id}
        clientName={clientFullName}
        amount={credit.amount}
        isOpen={rejectModal}
        handleClose={handleCloseRejectModal}
        onSuccess={handleSuccessReject}
      />
    </StyledContainer>
  )
}

export default CreditsValidator
