import { FunctionComponent } from 'react'
import EmptyErrorState from 'components/EmptyErrorState'
import ImageError from 'assets/error.png'
import { SizeEmptyErrorState } from 'types'
import { DocumentsTypes } from '../types'
import { ApolloError } from 'apollo-client'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { pdfjs, Document, Page } from 'react-pdf'
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types'
import { PdfContainer } from './styled'
import 'pdfjs-dist/build/pdf.worker.entry'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

interface ViewerProps {
  selectedUrl: string
  selectedType: DocumentsTypes
  error: ApolloError | undefined
  numPages?: number
  refetch: () => void
  onLoadSuccess?: OnDocumentLoadSuccess
}

const Viewer: FunctionComponent<ViewerProps> = ({
  selectedType,
  selectedUrl,
  error,
  numPages = 1,
  refetch,
  onLoadSuccess,
}: ViewerProps) => {
  if (error) {
    return (
      <EmptyErrorState
        icon={ImageError}
        size={SizeEmptyErrorState.LARGE}
        textDescription="Parece que algo salió mal al cargar la imagen"
        buttonLabel="Volver a intentar"
        handleButton={refetch}
      />
    )
  } else {
    switch (selectedType) {
      case DocumentsTypes.INE_FRONT:
        return <img width="100%" src={selectedUrl} alt="ineFront" />
      case DocumentsTypes.INE_BACK:
        return <img width="100%" src={selectedUrl} alt="ineBack" />
      case DocumentsTypes.ACCEPTANCE_VIDEO:
        return (
          <video width="70%" controls>
            <source src={selectedUrl} type="video/mp4" />
            <source src={selectedUrl} type="video/ogg" />
          </video>
        )
      case DocumentsTypes.LEGAL_PDF:
        return (
          <PdfContainer>
            {selectedUrl && numPages ? (
              <Document
                onLoadSuccess={onLoadSuccess}
                loading="Cargando..."
                file={{ url: selectedUrl } as { url: string }}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            ) : (
              <p>Cargando...</p>
            )}
          </PdfContainer>
        )
      default:
        return null
    }
  }
}

export default Viewer
