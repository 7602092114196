import styled from '@emotion/styled'
import { Alert, Radio, TextField, Typography } from '@mui/material'
import { theme } from 'theme/theme'

export const Container = styled.form`
  width: 31.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export const StyledAlert = styled(Alert)`
  font-family: ${theme.typography.fontFamily};
  color: ${theme.typographyColor.primaryText};
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.iconWarningColor};
  }
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const StyledTypography = styled(Typography)`
  display: flex;
  gap: 4px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  width: 100%;
`

export const StyledRadio = styled(Radio)`
  color: black;
  &.Mui-checked {
    color: green;
  }
`

export const GenericInput = styled(TextField)`
  width: 100%;
  color: ${theme.typographyColor.secondaryText};
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.iconBasicColor};
  }
`
