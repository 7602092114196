import styled from '@emotion/styled'
import { Avatar } from '@mui/material'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
`

export const StyledAvatar = styled(Avatar)`
  width: 108px;
  height: 108px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`
