import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Credit, PromoterStructure, Prospect } from 'types'
import { GET_CREDIT_BY_ID, GET_CLIENT_BY_ID, GET_PROMOTER_BY_ID } from '../queries'
import { defaultCreditData, defaultProspectValue } from '../values'

interface Props {
  id?: string | undefined
}

export const useData = ({ id }: Props) => {
  const [credit, setCredit] = useState<Credit>(defaultCreditData)
  const [client, setClient] = useState<Prospect>(defaultProspectValue)
  const [clientId, setClientId] = useState('')
  const [promoterName, setPromoterName] = useState('')
  const [promoterBalance, setPromoterBalance] = useState(0)
  const [
    getCreditById,
    { error: errorCreditData, loading: loadingCreditData, refetch: refetchCredit },
  ] = useLazyQuery(GET_CREDIT_BY_ID)
  const [
    getClientData,
    { error: errorClientData, loading: loadingClientData, refetch: refetchClient },
  ] = useLazyQuery(GET_CLIENT_BY_ID)
  const [getPromoterData, { error: errorPromoterData, refetch: refetchPromoter }] =
    useLazyQuery(GET_PROMOTER_BY_ID)

  useEffect(() => {
    if (typeof id === 'string' && id !== defaultCreditData.id) {
      const getData = async () => {
        const data = (await getCreditById({
          variables: {
            creditId: id,
          },
        })) as unknown as { data: { getCreditById: Credit } }
        const credit = data?.data?.getCreditById || defaultCreditData
        setCredit(credit)
        setClientId(credit.clientId)
      }
      getData()
    }
  }, [id])

  useEffect(() => {
    if (clientId && clientId !== defaultCreditData.clientId && !loadingCreditData) {
      const getData = async () => {
        const data = (await getClientData({
          variables: { clientId: credit.clientId },
        })) as unknown as { data: { getClientsById: Prospect } }
        const client = data?.data?.getClientsById || defaultProspectValue
        setClient(client)
      }
      getData()
    }
  }, [clientId, loadingCreditData])

  useEffect(() => {
    if (clientId && credit?.createdBy && !loadingClientData) {
      const getData = async () => {
        const data = (await getPromoterData({
          variables: { promoterId: credit.createdBy },
        })) as unknown as { data: { getPromoterById: PromoterStructure } }
        const { data: promoterData } = data || {}
        const { getPromoterById } = promoterData || {}
        const { firstLastName, firstName, secondLastName, creditBalance } = getPromoterById || {}
        const name = `${firstName || ''} ${firstLastName || ''} ${secondLastName || ''}`
        if (typeof creditBalance === 'number') setPromoterBalance(creditBalance)
        setPromoterName(name)
      }
      getData()
    }
  }, [credit?.id, clientId, credit?.createdBy, loadingClientData])

  return {
    credit,
    client,
    promoterName,
    errorClientData,
    errorCreditData,
    errorPromoterData,
    promoterBalance,
    refetchClient,
    refetchCredit,
    refetchPromoter,
  }
}
