import styled from '@emotion/styled'
import { theme } from 'theme/theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`

export const TitleContainer = styled.div`
  display: flex;
  padding: 16px 16px 0px 8px;
  align-items: center;
`
export const StyledIcon = styled(ArrowBackIcon)`
  color: ${theme.typographyColor.iconBasicColor};
`
export const AllContainer = styled.div`
  display: flex;
  padding: 16px 16px 0px 16px;
  justify-content: space-between;
  align-items: center;
`
export const datePickerCss = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    background-color: ${theme.extraBackgrounds.activeToggleButton};
  }
  button.my-selected:hover:not([disabled]) { 
    border-color: ${theme.extraBackgrounds.selectedItem};
    border-radius: 50%;
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: ${theme.extraBackgrounds.selectedItem};
  }
  .rdp-nav_button {
    color: #216243;
  }
  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-radius: 50%;
    background: ${theme.extraBackgrounds.selectedItem};
  }
  .my-selected.rdp-day_range_start{
    background: ${theme.extraBackgrounds.selectedItem};
  }
  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-radius: 50%;
  }
  .my-today { 
    border: 1px solid gray;
  }
`
