import { AnimalResponse } from 'types'
import api from './api'

const animalsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getRandomImage: build.query<AnimalResponse, void>({
      query: () => '/breeds/image/random',
    }),
  }),
})

export const { useGetRandomImageQuery } = animalsAPI
