import { useState, useEffect, FC } from 'react'
import CustomDataGrid from 'components/CustomDataGrid'
import { CreditByStatus, CreditStatus, PaymentSortModel } from 'types'
import { useQuery } from '@apollo/client'
import { GET_CREDIT_BY_STATUS } from '../queries'
import { columnsPending } from '../Columns'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { OPTIONS_ROWS_PER_PAGE, ORDER, ORDER_BY_CREATED_AT } from '..'
import { DateRange } from 'react-day-picker'
import { useNavigate } from 'react-router-dom'
import { NetworkError } from 'components/CustomDataGrid/EmptyStates/NetworkError'
import { RequestError } from 'components/CustomDataGrid/EmptyStates/RequestError'
import { NoData } from 'components/CustomDataGrid/EmptyStates/NoData'

type Props = {
  inputFilter: string
  sortModel: string | null
  dateRange: DateRange | undefined
  handleFilterButton: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Pending: FC<Props> = ({ inputFilter, dateRange, handleFilterButton }) => {
  const navigate = useNavigate()
  const [pendingPayments, setPendingPayments] = useState<CreditByStatus[]>([])
  const [totalPayments, setTotalPayments] = useState<number>(0)
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(OPTIONS_ROWS_PER_PAGE[0])
  const initialStateGrid: GridInitialStateCommunity = {
    pagination: {
      paginationModel: {
        page: page,
        pageSize: rowsPerPage,
      },
    },
  }
  const sortModel: GridSortModel = [{ field: ORDER_BY_CREATED_AT, sort: ORDER }]
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)

  const { loading, data, error, refetch } = useQuery(GET_CREDIT_BY_STATUS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: page,
      limit: rowsPerPage,
      order: ORDER,
      orderBy: ORDER_BY_CREATED_AT,
      filters: {
        statuses: CreditStatus.IN_REVIEW,
        clientName: filterSearch,
        datesFilter: {
          dates: {
            startDate: dateRange?.from?.toISOString(),
            endDate: dateRange?.to?.toISOString(),
          },
          filterField: PaymentSortModel.CREATION,
        },
      },
    },
  })

  const handleChangePage = (objectPages: GridPaginationModel) => {
    setPage(objectPages.page)
    setRowsPerPage(objectPages.pageSize)
  }

  const handleErrorButton = async () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      refetch()
    }
  }

  const handleClickVerifyButton = (id: string) => {
    navigate(`/validate-credit/${id}`)
  }

  useEffect(() => {
    if (!loading && !error && data) {
      const pendingCredits: CreditByStatus[] = data?.getCreditsByStatus ?? []

      setTotalPayments(pendingPayments.length)
      setPendingPayments(pendingCredits)
    }
  }, [data, loading, error, dateRange])

  useEffect(() => {
    setPage(0)
    setFilterSearch(inputFilter)
  }, [inputFilter])

  const hasNoData =
    pendingPayments.length === 0 && filterSearch.length === 0 && !dateRange && !loading

  if (!isOnlineState) return <NetworkError handleRetry={handleErrorButton} />
  if (error) return <RequestError handleRetry={handleErrorButton} />
  if (hasNoData) return <NoData message="No hay órdenes de pago pendientes" />

  return (
    <>
      <CustomDataGrid
        title="Listado de créditos pendientes"
        loading={loading}
        data={pendingPayments}
        dataColumns={columnsPending({ handleClickVerifyButton })}
        initialState={initialStateGrid}
        pageSizeOptions={OPTIONS_ROWS_PER_PAGE}
        handleChangePage={handleChangePage}
        sort={sortModel}
        page={page}
        pageSize={rowsPerPage}
        totalRows={totalPayments}
        filterButton={{ handleClick: handleFilterButton }}
      />
    </>
  )
}

export default Pending
