import { FunctionComponent, ElementType, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import find from 'lodash/find'
import { RouteType, routes } from './routes'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalState } from 'redux/store'
import { actions } from 'redux/UserReducer'

type Props = {
  component: FunctionComponent
  layout: ElementType
}

const GMT_TIME = 6

const PrivateRoute = ({ component: Component, layout: Layout }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isAuthenticated, user } = useSelector((state: GlobalState) => state.user)
  const dispatch = useDispatch()
  const { logout } = actions

  useEffect(() => {
    validateRoute()
  }, [location])

  const validateRoute = () => {
    const hasAccess = find(
      routes,
      ({ path }: RouteType) => path.split('/')[1] === location.pathname.split('/')[1],
    )
    if (!hasAccess) navigate('/login')

    validateSesionToken()
  }

  const validateSesionToken = () => {
    const expSession = user?.signInUserSession?.idToken?.payload.exp
    if (expSession) {
      const expSessionDate = new Date(0)
      expSessionDate.setUTCSeconds(expSession)
      expSessionDate.setUTCHours(expSessionDate.getUTCHours())

      const today = new Date()
      if (today > expSessionDate) {
        dispatch(logout())
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  }

  return (
    <>
      {isAuthenticated ? (
        <>
          <Layout>
            <Component />
          </Layout>
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  )
}

export default PrivateRoute
