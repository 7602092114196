import { FC, useEffect, useState } from 'react'
import { BottomContent, TopContent } from '../styled'
import HeaderContent from '../HeaderContent'
import {
  ContainerButtons,
  ContainerContact,
  ContainerInfoPromoter,
  ContainerName,
  ContainerOptions,
  StyledBackButton,
  StyledLinkButton,
  StyledOutlineButton,
  ClabeContact,
  WarningContainer,
  PictureContainer,
  ImageSelector,
} from './styled'
import { Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { theme } from 'theme/theme'
import { CheckCircleOutline, ChevronLeft, OpenInNew } from '@mui/icons-material'
import { Promoter } from '..'
import { StyledAlert } from 'screens/Login/styled'
import { useMutation } from '@apollo/client'
import { ADD_PROMOTER } from '../queries'
import { REACT_APP_LENDUS_URL } from 'config/appConfig'
import { formatPhoneNumber } from 'utils'
import { GenericInput } from 'components/common/styled'

const MAXIMUM_PROMOTER_PIC_BYTES_SIZE = 75000
const ERROR_MESSAGE_API = 'Parece que algo salio mal. Inténtalo de nuevo.'
const ERROR_MESSAGE_UPLOAD_PROMOTER_PIC =
  'El peso de la foto de la promotora no debe exceder de 75 kB.'

type Props = {
  selectedPromoter: Promoter | null
  isOpenModal: boolean
  handleChangeStep: (step: number) => void
  handleSelectedPromoter: (promoter: Promoter | null) => void
}

const LENDUS_LINK = `${REACT_APP_LENDUS_URL}/org_employee/edit`
const STEPS = {
  NEXT: 3,
  ID_STEP: 2,
  BACK: 1,
}

const AddPromoter: FC<Props> = ({
  selectedPromoter,
  isOpenModal,
  handleChangeStep,
  handleSelectedPromoter,
}) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [speiNumber, setSpeiNumber] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [promoterPicture, setPromoterPicture] = useState<string>('')
  const fullName = `${selectedPromoter?.firstName} ${selectedPromoter?.firstLastName} ${selectedPromoter?.secondLastName}`
  const [addPromoter, { loading }] = useMutation(ADD_PROMOTER)
  const [errorUploadPromoterPic, setErrorUploadPromoterPic] = useState(false)

  useEffect(() => {
    if (!isOpenModal) {
      handleSelectedPromoter(null)
    }
  }, [isOpenModal])

  const handleBackStep = () => {
    handleChangeStep(STEPS.BACK)
    handleSelectedPromoter(null)
  }

  const handleChangeText = (text: string) => {
    const result = text.replace(/\D/g, '')
    setSpeiNumber(result)
  }

  const handleFileRead = async (pic: File) => {
    const file = pic
    const base64 = await convertBase64(file)
    if (typeof base64 === 'string') {
      setPromoterPicture(base64)
    }
  }

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleAddPromoter = async () => {
    try {
      const { data } = await addPromoter({
        variables: {
          promoter: {
            id: `${selectedPromoter?.id}`,
            firstLastName: selectedPromoter?.firstLastName,
            secondLastName: selectedPromoter?.secondLastName,
            firstName: selectedPromoter?.firstName,
            phoneNumber: selectedPromoter?.phoneNumber,
            speiNumber: speiNumber,
            promoterPic: promoterPicture,
          },
        },
      })
      if (data?.savePromoter) {
        handleChangeStep(STEPS.NEXT)
      } else {
        setHasError(true)
      }
    } catch (error) {
      setHasError(true)
    }
  }

  return (
    <>
      <TopContent>
        <HeaderContent
          title="Agregar Promotora"
          description="Al agregar a la promotora se generará una contraseña única con la que podrá acceder a la aplicación móvil FONI Crédito"
        />
      </TopContent>
      <BottomContent gap={24}>
        <ContainerInfoPromoter>
          <ContainerName>
            <Typography variant="body2" color={theme.typographyColor.secondaryText}>
              Nombre
            </Typography>
            <Typography variant="body1" color={theme.typographyColor.primaryText}>
              {fullName}
            </Typography>
          </ContainerName>
          <ContainerOptions>
            <ContainerContact>
              <Typography variant="body2" color={theme.typographyColor.secondaryText}>
                Número de teléfono
              </Typography>
              <Typography variant="body1" color={theme.typographyColor.primaryText}>
                {formatPhoneNumber(selectedPromoter?.phoneNumber || '', true)}
              </Typography>
            </ContainerContact>
            <StyledLinkButton href={`${LENDUS_LINK}/${selectedPromoter?.id}`} target="_blank">
              <StyledOutlineButton variant="outlined" endIcon={<OpenInNew />}>
                Editar
              </StyledOutlineButton>
            </StyledLinkButton>
          </ContainerOptions>
          <ClabeContact>
            <GenericInput
              value={speiNumber}
              inputProps={{ maxLength: 18, placeholder: 'CLABE' }}
              label="CLABE"
              onChange={(event) => handleChangeText(event.target.value)}
            />
            {speiNumber.length !== 18 && speiNumber !== '' && (
              <WarningContainer>
                <Typography variant="body2" color={theme.typographyColor.errorText}>
                  Ingresa una CLABE válida a 18 dígitos
                </Typography>
              </WarningContainer>
            )}
          </ClabeContact>
          <Typography variant="body2" color={theme.typographyColor.secondaryText}>
            Foto de promotora
          </Typography>
          <PictureContainer>
            <ImageSelector
              type="file"
              name="myImage"
              onChange={(event) => {
                if (event.target.files !== null) {
                  const file = event.target.files[0]
                  if (file.size > MAXIMUM_PROMOTER_PIC_BYTES_SIZE) {
                    setErrorUploadPromoterPic(true)
                  }
                  setSelectedImage(file)
                  handleFileRead(file)
                }
              }}
            />
            {selectedImage && (
              <div>
                <img alt="not found" width={'250px'} src={URL.createObjectURL(selectedImage)} />
                <br />
                <button
                  onClick={() => {
                    setSelectedImage(null)
                    setErrorUploadPromoterPic(false)
                  }}
                >
                  Remover
                </button>
              </div>
            )}
          </PictureContainer>
        </ContainerInfoPromoter>
        {(hasError || errorUploadPromoterPic) && (
          <StyledAlert severity="error">
            {errorUploadPromoterPic ? ERROR_MESSAGE_UPLOAD_PROMOTER_PIC : ERROR_MESSAGE_API}
          </StyledAlert>
        )}
        <ContainerButtons>
          <StyledBackButton variant="text" startIcon={<ChevronLeft />} onClick={handleBackStep}>
            Atrás
          </StyledBackButton>
          <LoadingButton
            variant="contained"
            disableElevation
            onClick={handleAddPromoter}
            loading={loading}
            loadingPosition="end"
            endIcon={<CheckCircleOutline />}
            disabled={
              speiNumber.length !== 18 ||
              (selectedImage instanceof File &&
                selectedImage.size > MAXIMUM_PROMOTER_PIC_BYTES_SIZE)
            }
          >
            Agregar promotora
          </LoadingButton>
        </ContainerButtons>
      </BottomContent>
    </>
  )
}

export default AddPromoter
