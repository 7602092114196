import { GridRenderCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import { StyledChip } from 'components/CustomDataGrid/Chips'
import dayjs from 'dayjs'
import {
  FormattedDateEnum,
  formatPhoneNumber,
  formatToCurrency,
  formattedDate,
} from '../../../utils'
import { PromoterStructure } from 'types'
import MenuButton from 'screens/Promoters/MenuButton'
import { theme } from 'theme/theme'
import { StyledDiv } from './styled'
dayjs.locale('es')

const LAST_DIGITS_TO_COUNT = 6

const getReferenceNumber = (phoneNumber: string) =>
  phoneNumber.substring(phoneNumber.length - LAST_DIGITS_TO_COUNT)

export const getPromotersColumns = ({
  setPromoter,
  openApplyPayment,
}: {
  setPromoter: (promoter: PromoterStructure) => void
  openApplyPayment: () => void
}): GridColDef[] => [
  {
    field: 'fullName',
    headerName: 'Nombre',
    flex: 1.5,
    filterable: false,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { firstName, firstLastName, secondLastName } = row
      return <div>{`${firstName} ${firstLastName} ${secondLastName}`}</div>
    },
    valueGetter: ({ row }: GridRenderCellParams) =>
      `${row.firstName} ${row.firstLastName} ${row.secondLastName}`,
  },
  {
    field: 'referenceNumber',
    headerName: 'Referencia',
    flex: 1,
    filterable: false,
    renderCell: ({ row }: GridRenderCellParams) => <div>{getReferenceNumber(row.phoneNumber)}</div>,
    valueGetter: (params: { row: { phoneNumber: string } }) =>
      getReferenceNumber(params.row.phoneNumber),
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { phoneNumber } = row
      return <div>{`+52 ${formatPhoneNumber(phoneNumber)}`}</div>
    },
  },
  {
    field: 'numberActiveClients',
    headerName: 'Clientes Activos',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { numberActiveClients } = row
      return <StyledDiv color={theme.palette.text.primary}>{numberActiveClients || 0}</StyledDiv>
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de Creación',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: ({ value }: GridValueFormatterParams) => {
      const date = formattedDate(value as string, FormattedDateEnum.FULL)
      return date
    },
  },
  {
    field: 'status',
    headerName: 'Estatus',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { status } = row
      return (
        <StyledChip
          promoteractive={status.toLowerCase()}
          label={status.toLowerCase() === 'active' ? 'Activo' : 'Inactivo'}
        />
      )
    },
  },
  {
    field: 'pendingAmount',
    headerName: 'Monto por Aplicar',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { pendingAmount } = row
      return (
        <StyledDiv
          color={pendingAmount > 0 ? theme.palette.error.main : theme.palette.text.primary}
        >
          {formatToCurrency(pendingAmount)}
        </StyledDiv>
      )
    },
  },
  {
    field: 'totalToPay',
    headerName: 'Meta semanal',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { totalToPay } = row
      return (
        <StyledDiv color={totalToPay > 0 ? theme.palette.error.main : theme.palette.text.primary}>
          {formatToCurrency(totalToPay)}
        </StyledDiv>
      )
    },
  },
  {
    field: 'creditBalance',
    headerName: 'Línea de crédito',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { creditBalance } = row
      return (
        <StyledDiv color={theme.palette.text.primary}>{formatToCurrency(creditBalance)}</StyledDiv>
      )
    },
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <MenuButton
          openApplyPayment={openApplyPayment}
          selectPromoter={setPromoter}
          promoter={row}
        />
      )
    },
  },
]
