import React, { useState } from 'react'
import {
  Column,
  Container,
  FormContainer,
  ImageContainer,
  FormSection,
  Section,
  LoginFormContainer,
  EmailContainer,
  ErrorMessage,
  StyledAlert,
  StyledLink,
  StyledContainer,
} from './styled'
import chatDude from 'assets/chatdude.png'
import logoFoni from 'assets/logoFoni.svg'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Button, IconButton, InputAdornment, Typography } from '@mui/material'
import { theme } from 'theme/theme'
import { User } from 'types'
import { GenericInput } from 'components/common/styled'
import RecoverPassword from './components/RecoverPassword'
import { Auth } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'redux/UserReducer'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { GlobalState } from '../../redux/store'

const Login = () => {
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [errorUserCredentials, setErrorUserCredentials] = useState<boolean>(false)
  const [recoverPassword, setRecoverPassword] = useState<boolean>(false)
  const [userCredentials, setUserCredentials] = useState<User>({
    email: '',
    password: '',
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setUserData } = actions

  const { isAuthenticated } = useSelector((state: GlobalState) => state.user)

  const handleClickShowPassword = () => {
    setSeePassword((prev) => !prev)
  }

  const handleRecoverPassword = () => {
    setRecoverPassword((prev) => !prev)
  }

  const handleCredentialsError = (state: boolean) => {
    setErrorUserCredentials(state)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setUserCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const LogInFunction = async ({ email, password }: User) => {
    try {
      const user = await Auth.signIn(email, password)
      if (!user.signInUserSession) throw new Error('Error with user')
      dispatch(setUserData({ user: user, isAuthenticated: true }))
      navigate('/promoters')
    } catch (error) {
      setErrorUserCredentials(true)
    }
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    handleCredentialsError(false)
    setError(false)

    event.preventDefault()

    if (!validateEmail(userCredentials.email)) {
      setError(true)
      return
    }

    await LogInFunction({
      email: userCredentials.email,
      password: userCredentials.password,
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/promoters')
    }
  }, [isAuthenticated])

  return (
    <Container>
      <Column backgroundColor={theme.palette.primary.main + `1A`}>
        <ImageContainer src={chatDude} />
      </Column>
      <Column>
        <LoginFormContainer>
          <img src={logoFoni} alt="Logo" />
          {recoverPassword ? (
            <RecoverPassword goBackFunction={handleRecoverPassword} />
          ) : (
            <FormContainer onSubmit={handleFormSubmit}>
              <FormSection gap="8px">
                <Typography variant="h1" color={theme.typographyColor.primaryText}>
                  Inicia Sesión
                </Typography>
                <Typography variant="body1" color={theme.typographyColor.secondaryText}>
                  Ingresa tu correo y contraseña de acceso.
                </Typography>
              </FormSection>
              <FormSection gap="24px">
                <EmailContainer>
                  <GenericInput
                    type="text"
                    name="email"
                    label="Correo electrónico"
                    error={error}
                    onChange={handleOnChange}
                    InputProps={{
                      endAdornment: error ? (
                        <InputAdornment position="end">
                          <ErrorOutlineOutlinedIcon color="error" />
                        </InputAdornment>
                      ) : null,
                    }}
                    InputLabelProps={{
                      style: {
                        color: `${
                          error
                            ? `${theme.typographyColor.errorText}`
                            : `${theme.typographyColor.secondaryText}`
                        }`,
                        fontFamily: `${theme.typography.fontFamily}`,
                      },
                    }}
                  />
                  {error && <ErrorMessage>Ingresa un correo electrónico válido</ErrorMessage>}
                </EmailContainer>
              </FormSection>
              <StyledContainer>
                <GenericInput
                  InputLabelProps={{
                    style: {
                      color: `${theme.typographyColor.secondaryText}`,
                    },
                  }}
                  onChange={handleOnChange}
                  name="password"
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {seePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={seePassword ? 'text' : 'password'}
                  label="Contraseña"
                />
                <Section>
                  <Typography variant="body1" color={theme.typographyColor.secondaryText}>
                    ¿Olvidaste tu contraseña?
                  </Typography>
                  <StyledLink variant="text" onClick={handleRecoverPassword}>
                    <u>Recupérala aquí.</u>
                  </StyledLink>
                </Section>
                <StyledContainer marginTop="40px">
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={
                      !validateEmail(userCredentials.email) || userCredentials.password.length < 8
                    }
                  >
                    Iniciar sesión
                  </Button>
                </StyledContainer>
                {errorUserCredentials && (
                  <StyledContainer marginTop="40px">
                    <StyledAlert severity="error">
                      Correo electrónico o contraseña incorrectos
                    </StyledAlert>
                  </StyledContainer>
                )}
              </StyledContainer>
            </FormContainer>
          )}
        </LoginFormContainer>
      </Column>
    </Container>
  )
}

export default Login
