import { SizeEmptyErrorState } from 'types'
import { EmptyContainer } from 'components/common/styled'
import EmptyErrorState from 'components/EmptyErrorState'
import ImageError from 'assets/error.png'

interface RequestErrorProps {
  handleRetry: () => void
}

export const RequestError = ({ handleRetry }: RequestErrorProps) => {
  return (
    <EmptyContainer paddingVertical={75}>
      <EmptyErrorState
        icon={ImageError}
        size={SizeEmptyErrorState.LARGE}
        textDescription="Parece que algo salió mal al cargar la información"
        buttonLabel="Volver a intentar"
        handleButton={handleRetry}
      />
    </EmptyContainer>
  )
}
