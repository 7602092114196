import React from 'react'
import { useGetRandomImageQuery } from 'data/animals'

function About() {
  const { isLoading, data } = useGetRandomImageQuery()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      About Page
      <img src={data?.message || ''} alt="dummie" />
    </div>
  )
}

export default About
