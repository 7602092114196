import styled from '@emotion/styled'
import { Chip } from '@mui/material'
import { theme } from 'theme/theme'

type StyleProps = {
  promoteractive: string
}

export const StyledChip = styled(Chip)<StyleProps>`
  background-color: ${({ promoteractive }) =>
    promoteractive === 'active'
      ? theme.extraBackgrounds.activeChip
      : theme.extraBackgrounds.inactiveChip};
  color: ${({ promoteractive }) =>
    promoteractive === 'active'
      ? theme.typographyColor.onSuccess
      : theme.typographyColor.errorText};
`
