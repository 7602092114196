import styled from '@emotion/styled'
import { Alert, Snackbar } from '@mui/material'

export const StyledSnackbarAlert = styled(Snackbar)`
  width: 343px;
  @media (min-width: 600px) {
    top: 108px;
    right: 40px;
  }
`

export const StyledAlert = styled(Alert)`
  width: 100%;
`
