import { createSlice } from '@reduxjs/toolkit'

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    counter: 0,
  },
  reducers: {
    incremented: (state) => {
      state.counter += 1
    },
    decremented: (state) => {
      state.counter -= 1
    },
  },
})

const { actions } = homeSlice

export { actions }

export default homeSlice.reducer
