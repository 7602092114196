import { useEffect, useState } from 'react'
import { InfoContainer, StyledContainer } from './styled'
import { Divider, Typography } from '@mui/material'
import AditionalIncomes from 'components/AditionalIncomes'
import {
  AgeOfHousingEnum,
  EconomicData,
  HousingTypeEnum,
  Income,
  IncomePeriodicityEnum,
  IncomeTypeEnum,
  WorkingLengthEnum,
} from 'types'

interface Props {
  economicData?: EconomicData
}
const ProspectEconomics = ({ economicData }: Props) => {
  const { housingType, ageOfHousing, economicDependants, incomes } = economicData || {}
  const [mainIncome, setMainIncome] = useState<Income>()
  const [secondaryIncomes, setSecondaryIncomes] = useState<Income[]>([])

  const translateHousingType = (type: string): string => {
    switch (type) {
      case HousingTypeEnum.OWNED:
        return 'Casa Propia'
      case HousingTypeEnum.RENTED:
        return 'Casa Alquilada'
      case HousingTypeEnum.BORROWED:
        return 'Casa Prestada'
      default:
        return 'Desconocido' // Maneja valores no esperados
    }
  }

  const translateAgeOfHousing = (age: string): string => {
    switch (age) {
      case AgeOfHousingEnum.LESS_THAN_1_YEAR:
        return 'Menos de 1 año'
      case AgeOfHousingEnum.BETWEEN_1_AND_5_YEARS:
        return 'Entre 1 y 5 años'
      case AgeOfHousingEnum.BETWEEN_5_AND_10_YEARS:
        return 'Entre 5 y 10 años'
      case AgeOfHousingEnum.BETWEEN_10_AND_20_YEARS:
        return 'Entre 10 y 20 años'
      case AgeOfHousingEnum.MORE_THAN_20_YEARS:
        return 'Más de 20 años'
      default:
        return 'Desconocido' // Maneja valores no esperados
    }
  }

  const describeEconomicDependants = (count: number): string => {
    if (count === 0) {
      return 'Sin dependientes económicos'
    } else if (count === 1) {
      return '1 dependiente económico'
    } else {
      return `${count} dependientes económicos`
    }
  }

  const translateIncomeType = (type: string): string => {
    switch (type) {
      case IncomeTypeEnum.EMPLOYEE:
        return 'Empleado'
      case IncomeTypeEnum.INDEPENDENT:
        return 'Independiente'
      case IncomeTypeEnum.OTHER:
        return 'Otro'
      default:
        return 'Desconocido'
    }
  }

  const formatCurrencyMXN = (amount: number): string => {
    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    return formatter.format(amount)
  }

  const translateWorkingLength = (length: string) => {
    switch (length) {
      case WorkingLengthEnum.LESS_THAN_1_YEAR:
        return 'Menos de 1 año'
      case WorkingLengthEnum.BETWEEN_1_AND_3_YEARS:
        return 'Entre 1 y 3 años'
      case WorkingLengthEnum.BETWEEN_3_AND_5_YEARS:
        return 'Entre 3 y 5 años'
      case WorkingLengthEnum.MORE_THAN_5_YEARS:
        return 'Más de 5 años'
      default:
        return 'Desconocido'
    }
  }

  const translateIncomePeriodicity = (periodicity: string) => {
    switch (periodicity) {
      case IncomePeriodicityEnum.WEEKLY:
        return 'Semanal'
      case IncomePeriodicityEnum.BIWEEKLY:
        return 'Quincenal'
      case IncomePeriodicityEnum.MONTHLY:
        return 'Mensual'
      default:
        return 'Desconocido'
    }
  }

  useEffect(() => {
    setMainIncome(incomes?.find((data) => data.isMain === true))
    setSecondaryIncomes(incomes?.filter((data) => data.isMain !== true) || [])
  }, [economicData, incomes])

  return (
    <StyledContainer>
      <Typography variant="h3">Vivienda y Dependientes Económicos</Typography>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Tipo de vivienda
        </Typography>
        <Typography variant="body1">{translateHousingType(housingType || '')}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Antigüedad en la vivienda
        </Typography>
        <Typography variant="body1">{translateAgeOfHousing(ageOfHousing || '')}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Dependientes económicos
        </Typography>
        <Typography variant="body1">
          {describeEconomicDependants(economicDependants || 0)}
        </Typography>
      </InfoContainer>
      <Divider />
      <Typography variant="h3">Ingreso Principal</Typography>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Tipo de ingreso
        </Typography>
        <Typography variant="body1">{translateIncomeType(mainIncome?.incomeType || '')}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Ingresos
        </Typography>
        <Typography variant="body1">{formatCurrencyMXN(mainIncome?.amount || 0)}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Periodicidad
        </Typography>
        <Typography variant="body1">
          {translateIncomePeriodicity(mainIncome?.periodicity || '')}
        </Typography>
      </InfoContainer>
      {mainIncome?.incomeType === IncomeTypeEnum.EMPLOYEE && (
        <>
          <InfoContainer>
            <Typography variant="body2" color="GrayText">
              Nombre del empleador
            </Typography>
            <Typography variant="body1">{mainIncome?.nameOfEmployer}</Typography>
          </InfoContainer>
          <InfoContainer>
            <Typography variant="body2" color="GrayText">
              Antigüedad
            </Typography>
            <Typography variant="body1">
              {translateWorkingLength(mainIncome?.workExperience || '')}
            </Typography>
          </InfoContainer>
        </>
      )}
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Puesto
        </Typography>
        <Typography variant="body1">
          {mainIncome?.incomeType === IncomeTypeEnum.EMPLOYEE
            ? mainIncome?.employmentPosition
            : mainIncome?.description}
        </Typography>
      </InfoContainer>
      <Divider />
      {secondaryIncomes?.length > 0 ? (
        <Typography variant="h3">Ingresos Adicionales</Typography>
      ) : null}
      {secondaryIncomes?.map((data, index) => {
        return <AditionalIncomes key={index} income={data} index={index}></AditionalIncomes>
      })}
    </StyledContainer>
  )
}

export default ProspectEconomics
