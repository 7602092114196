import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  gap: 24px;
`

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  text-align: center;
`

type StyledImageProps = {
  widthIcon?: number
}

export const StyledImage = styled.img<StyledImageProps>`
  width: ${({ widthIcon }) => (widthIcon ? `${widthIcon}px` : 'auto')};
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
