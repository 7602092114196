import { FC } from 'react'
import { Typography } from '@mui/material'
import { theme } from 'theme/theme'

type Props = {
  title: string
  description: string
}

const HeaderContent: FC<Props> = ({ title, description }) => {
  return (
    <>
      <Typography variant="h1" color={theme.typographyColor.primaryText}>
        {title}
      </Typography>
      <Typography variant="body1" color={theme.typographyColor.primaryText}>
        {description}
      </Typography>
    </>
  )
}

export default HeaderContent
