import React, { useState } from 'react'
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { StyledIconButton } from './styled'
import { theme } from 'theme/theme'
import { REACT_APP_LENDUS_URL } from 'config/appConfig'

interface Props {
  handleSeeRequest: () => void
  idProspect: string
}

const LENDUS_LINK = `${REACT_APP_LENDUS_URL}/request`

const MenuButton = ({ handleSeeRequest, idProspect }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSeeInLendus = () => {
    window.open(`${LENDUS_LINK}?id=${idProspect}`, '_blank')
  }

  return (
    <div>
      <StyledIconButton onClick={handleClick}>
        <MoreVertSharpIcon />
      </StyledIconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSeeRequest}>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Ver Solicitud
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleSeeInLendus}>
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Ver en Lendus
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default MenuButton
