import { gql } from '@apollo/client'

export const ACCEPT_CLIENT = gql`
  mutation AcceptClient($acceptClientId: String!) {
    acceptClient(id: $acceptClientId) {
      acceptedAt
      createdBy
    }
  }
`
