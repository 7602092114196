import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import { theme } from 'theme/theme'

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.iconBasicColor};
  }
`
