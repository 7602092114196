import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { theme } from 'theme/theme'

export const Container = styled.div`
  flex: 0 0 232px;
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: ${theme.extraBackgrounds.paper01};
`

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
`

export const LogoIcon = styled.img`
  width: 93.853px;
  height: 56px;
`

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  list-style: none;
  padding: 0px;
  margin: 0px;
`

export const ContainerOption = styled.li`
  cursor: pointer;
`

interface OptionProps {
  selected?: boolean
}

export const Option = styled(Link)<OptionProps>`
  text-decoration: none;
  ${({ selected }) =>
    selected
      ? `
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      color: ${theme.typographyColor?.primaryText};
      ::before {
        content: '';
        width: 4px;
        height: 48px;
        background: ${theme.extraBackgrounds.selectedItem};
      }`
      : `
      display: block;
      padding: 12px 16px;
      color: ${theme.typographyColor?.secondaryText};
  `}
`

export const BottomContent = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 16px;
`
