import styled from '@emotion/styled'
import { Button, IconButton } from '@mui/material'
import { theme } from 'theme/theme'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
  vertical-align: top;
  background-color: red;
  border-bottom: 1px solid #eee;
  background: #fff;
`

export const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledIconButton = styled(IconButton)`
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.primaryText};
  }
`

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const RejectionIconButton = styled(Button)`
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.errorText};
  }
  border-radius: 8px;
  border: 1px solid ${theme.typographyColor.errorText};
  color: ${theme.typographyColor.errorText};
  padding: 12px 16px;
  &:hover {
    border: 1px solid ${theme.typographyColor.errorText};
  }
`

export const AcceptedIconButton = styled(Button)`
  .MuiSvgIcon-root {
    color: ${theme.typographyColor.accentColor};
  }
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: ${theme.extraBackgrounds.selectedItem};
  color: ${theme.typographyColor.accentColor};
  padding: 12px 16px;
  &:hover {
    border: 1px solid ${theme.extraBackgrounds.selectedItem};
    background-color: ${theme.extraBackgrounds.selectedItem};
  }
`
export const StyledLink = styled.a`
  text-decoration: none;
`
