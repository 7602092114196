import styled from '@emotion/styled'
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { theme } from 'theme/theme'

export const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  padding: 16px 24px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
`

export const StyledGridToolbarFilterButton = styled(GridToolbarFilterButton)`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${theme.typographyColor.accentColor};
  color: ${theme.typographyColor.accentColor};
`

export const DataGridContainer = styled.div`
  height: 43rem;
`
export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`
export const NoResultsOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`
