import { InfoContainer, StyledContainer } from './styled'
import { Typography } from '@mui/material'
import { MaritalStatusEnum, Prospect } from 'types'
import { getFormattedAddress } from 'utils'
import { FunctionComponent } from 'react'

interface Props {
  client: Prospect
}

const maritalStatus = {
  [MaritalStatusEnum.SINGLE]: 'Soltero(a)',
  [MaritalStatusEnum.MARRIED]: 'Casado(a)',
  [MaritalStatusEnum.DIVORCED]: 'Divorciado(a)',
  [MaritalStatusEnum.WIDOWED]: 'Viudo(a)',
}

const getMaritalStatus = (status: MaritalStatusEnum) => {
  const found = maritalStatus[status]
  if (found) return found
  return 'Desconocido'
}

const ClientInformation: FunctionComponent<Props> = ({ client }: Props) => {
  const { firstName, firstLastName, secondLastName, contactData, personalData } = client || {}
  const { phoneNumber, phoneLada } = contactData || {}
  const { maritalStatus } = personalData || {}
  const textMaritalStatus = getMaritalStatus(maritalStatus as MaritalStatusEnum)
  const textAddress = getFormattedAddress(contactData)

  return (
    <StyledContainer>
      <Typography variant="h3">Información del cliente</Typography>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Nombre Completo
        </Typography>
        <Typography variant="body1">{`${firstName} ${firstLastName} ${secondLastName}`}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Número de teléfono
        </Typography>
        <Typography variant="body1">{`${phoneLada} ${phoneNumber}`}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Dirección
        </Typography>
        <Typography variant="body1">{textAddress}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Estado Civil
        </Typography>
        <Typography variant="body1">{textMaritalStatus}</Typography>
      </InfoContainer>
    </StyledContainer>
  )
}

export default ClientInformation
