import { FC, useState, useEffect } from 'react'
import { BottomContent, TopContent } from '../styled'
import {
  AnimateLoading,
  ContainerElement,
  ContainerForm,
  ContainerInput,
  StyledButton,
} from './styled'
import SearchInput from 'components/common/SearchInput'
import { SizeEmptyErrorState } from 'types'
import HeaderContent from '../HeaderContent'
import EmptyErrorState from 'components/EmptyErrorState'
import ElementPromoterFound from '../ElementPromoterFound'
import IconLoading from '../../../assets/loading.png'
import IconError from '../../../assets/error.png'
import IconGlass from '../../../assets/glass.png'
import { useLazyQuery } from '@apollo/client'
import { GET_PROMOTERS_LENDUS } from '../queries'
import { Promoter } from '..'

const EMPTY = 0
const MINIMUM_LETTERS = 3

type Props = {
  isOpenModal: boolean
  handleChangeStep: (step: number) => void
  handleSelectedPromoter: (promoter: Promoter) => void
}

const SearchPromoters: FC<Props> = ({ isOpenModal, handleChangeStep, handleSelectedPromoter }) => {
  const [searchText, setSearchText] = useState<string>('')
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(
    searchText.length <= MINIMUM_LETTERS,
  )
  const [foundPromoters, setFoundPromoters] = useState<Promoter[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const [firstAttempt, setFirstAttempt] = useState<boolean>(false)
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)

  const [getPromoterLendus, { data, error, loading }] = useLazyQuery(GET_PROMOTERS_LENDUS, {
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!isOpenModal) {
      setSearchText('')
      setHasError(false)
      setFoundPromoters([])
      setIsDisabledButton(true)
    }
  }, [isOpenModal])

  useEffect(() => {
    if (error) setHasError(true)
    else if (!loading && data?.getPromotersLendus) setFoundPromoters(data?.getPromotersLendus)
  }, [data, error, loading])

  const handleSearchText = (text: string) => {
    setSearchText(text)
    if (text.length >= MINIMUM_LETTERS) setIsDisabledButton(false)
    else setIsDisabledButton(true)
  }

  const handleSearchButton = () => {
    setIsOnlineState(navigator.onLine)
    setHasError(false)
    if (navigator.onLine) {
      getPromoterLendus({ variables: { search: searchText } })
      setFirstAttempt(true)
    }
  }

  const handleErrorButton = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      getPromoterLendus({ variables: { search: searchText } })
    }
  }

  return (
    <>
      <TopContent>
        <HeaderContent title="Buscar Promotora" description="Selecciona a una promotora" />
      </TopContent>
      <BottomContent gap={20}>
        <ContainerForm>
          <ContainerInput>
            <SearchInput
              placeholder="Buscar promotora"
              text={searchText}
              handleChangeText={handleSearchText}
            />
          </ContainerInput>
          <StyledButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleSearchButton}
            disabled={isDisabledButton}
          >
            Buscar
          </StyledButton>
        </ContainerForm>
        {loading ? (
          <AnimateLoading src={IconLoading} alt="loading icon" />
        ) : !isOnlineState ? (
          <EmptyErrorState
            textDescription="No tienes conexión a internet"
            extraText="Revisa tu conexión de internet e inténtalo de nuevo."
            buttonLabel="Volver a intentar"
            handleButton={handleErrorButton}
          />
        ) : hasError ? (
          <EmptyErrorState
            icon={IconError}
            widthIcon={95}
            textDescription="Parece que algo salió mal al cargar la información"
            buttonLabel="Volver a intentar"
            handleButton={handleErrorButton}
            size={SizeEmptyErrorState.SMALL}
          />
        ) : foundPromoters.length === EMPTY && firstAttempt ? (
          <EmptyErrorState
            icon={IconGlass}
            widthIcon={80}
            textDescription="Sin resultados"
            extraText="No encontramos ningún resultado basado en tu búsqueda."
            size={SizeEmptyErrorState.SMALL}
          />
        ) : (
          <ContainerElement>
            {foundPromoters.map((promoter, index) => (
              <ElementPromoterFound
                promoter={promoter}
                handleChangeStep={handleChangeStep}
                handleSelectedPromoter={handleSelectedPromoter}
                key={index}
              />
            ))}
          </ContainerElement>
        )}
      </BottomContent>
    </>
  )
}

export default SearchPromoters
