import { gql } from '@apollo/client'

export const GET_PROMOTERS_LENDUS = gql`
  query GetPromotersLendus($search: String!) {
    getPromotersLendus(search: $search) {
      id
      firstName
      firstLastName
      secondLastName
      phoneNumber
    }
  }
`

export const ADD_PROMOTER = gql`
  mutation SavePromoter($promoter: PromoterInput!) {
    savePromoter(promoter: $promoter) {
      id
      firstName
      firstLastName
      secondLastName
      phoneNumber
    }
  }
`
