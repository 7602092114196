import { MenuItem, Typography } from '@mui/material'
import { MenuOptionContainer } from './styled'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import { PaymentOrderStep, PaymentSortModel } from 'types'

interface Props {
  step: number
  handleSelectSortModel: (sortModel: string | null) => void
  handleSwitchOption: () => void
}

const FatherMenu = ({ step, handleSelectSortModel, handleSwitchOption }: Props) => {
  const renderOptions = () => {
    if (step === PaymentOrderStep.PENDINGS) {
      return (
        <MenuItem onClick={() => handleSelectSortModel(PaymentSortModel.CREATION)}>
          <MenuOptionContainer>
            <Typography variant="button">Fecha de Creación</Typography>
            <KeyboardArrowRightOutlinedIcon />
          </MenuOptionContainer>
        </MenuItem>
      )
    }

    if (step === PaymentOrderStep.APPLIEDS) {
      return (
        <MenuItem onClick={() => handleSelectSortModel(PaymentSortModel.APPLIED)}>
          <MenuOptionContainer>
            <Typography variant="button">Fecha de Creación</Typography>
            <KeyboardArrowRightOutlinedIcon />
          </MenuOptionContainer>
        </MenuItem>
      )
    }

    if (step === PaymentOrderStep.DISBURSEDS) {
      return (
        <>
          <MenuItem
            onClick={() => {
              handleSelectSortModel(PaymentSortModel.CREATION)
              handleSwitchOption()
            }}
          >
            <MenuOptionContainer>
              <Typography variant="button">Fecha de Creación</Typography>
              <KeyboardArrowRightOutlinedIcon />
            </MenuOptionContainer>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleSelectSortModel(PaymentSortModel.APPLIED)
              handleSwitchOption()
            }}
          >
            <MenuOptionContainer>
              <Typography variant="button">Fecha de Desembolso</Typography>
              <KeyboardArrowRightOutlinedIcon />
            </MenuOptionContainer>
          </MenuItem>
        </>
      )
    }

    if (step === PaymentOrderStep.REJECTED) {
      return (
        <MenuItem
          onClick={() => {
            handleSelectSortModel(PaymentSortModel.UPDATED)
            handleSwitchOption()
          }}
        >
          <MenuOptionContainer>
            <Typography variant="button">Última actualización</Typography>
            <KeyboardArrowRightOutlinedIcon />
          </MenuOptionContainer>
        </MenuItem>
      )
    }

    return null
  }

  return <div>{renderOptions()}</div>
}

export default FatherMenu
