import { Button, TextField, Typography } from '@mui/material'
import { ModalContainer } from './styled'
import { PaymentType } from 'types'
import { formatToCurrency } from 'utils'
import GenericModal from 'components/GenericModal'
import { useMutation } from '@apollo/client'
import { REJECT_CREDIT } from './queries'
import { ChangeEvent, useState } from 'react'
import { StyledAlert } from 'screens/Login/styled'

interface ModalRejectOperationProps {
  paymentType: PaymentType
  creditId: string
  clientName: string
  amount: number
  isOpen: boolean
  handleClose: () => void
  onSuccess: () => void
}

export const ModalRejectOperation = ({
  paymentType,
  creditId,
  clientName,
  amount,
  isOpen,
  handleClose,
  onSuccess,
}: ModalRejectOperationProps) => {
  const [rejectInfo, setRejectInfo] = useState('')
  const [rejectCredit] = useMutation(REJECT_CREDIT)
  const [hasError, setHasError] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const loadingText = loading ? 'Rechazando' : 'Rechazar'
  const rejectionTypeMessage =
    paymentType === PaymentType.PAYMENT_ORDER
      ? `${loadingText} orden de pago`
      : `${loadingText} transferencia bancaria`

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRejectInfo(event.target.value)
  }

  const handleRejectCredit = async () => {
    if (hasError) setHasError(false)

    try {
      setLoading(true)
      const { data } = await rejectCredit({
        variables: {
          creditId: creditId,
          rejectInfo: rejectInfo,
        },
      })
      if (data?.rejectCredit) {
        setLoading(false)
        onSuccess()
      } else {
        setLoading(false)
        setHasError(true)
      }
    } catch (error) {
      setLoading(false)
      setHasError(true)
    }
  }

  const closeModal = () => {
    if (hasError) setHasError(false)
    if (!loading) handleClose()
  }

  return (
    <GenericModal isOpen={isOpen} closeModalFunction={closeModal}>
      <ModalContainer>
        <section>
          <Typography variant="h1">{rejectionTypeMessage}</Typography>
          <Typography variant="body1">
            Se le notificará a la promotora y deberá de dar de alta de nuevo el crédito.
          </Typography>
        </section>
        <section>
          <Typography variant="body2">Cliente</Typography>
          <Typography variant="body1">{clientName}</Typography>
        </section>
        <section>
          <Typography variant="body2">Monto</Typography>
          <Typography variant="body1">{formatToCurrency(amount)}</Typography>
        </section>
        <TextField
          multiline
          minRows={4}
          maxRows={4}
          placeholder="Comentarios"
          onChange={handleChange}
          disabled={loading}
        />
        <Button disabled={loading} color="primary" variant="contained" onClick={handleRejectCredit}>
          {rejectionTypeMessage}
        </Button>
        {hasError && (
          <StyledAlert severity="error">Parece que algo salió mal. Inténtalo de nuevo.</StyledAlert>
        )}
      </ModalContainer>
    </GenericModal>
  )
}
