import { gql } from '@apollo/client'

export const GET_PROMOTERS_COUNT = gql`
  query GetClientsCount {
    getClientsCount {
      accepted
      automaticVerification
      pending
      rejected
    }
  }
`

export const GET_PROMOTERS_BY_STATUS = gql`
  query GetClientsListByStatus(
    $page: Int!
    $limit: Int!
    $search: String!
    $statuses: [ClientStatus!]!
  ) {
    getClientsListByStatus(page: $page, limit: $limit, search: $search, statuses: $statuses) {
      id
      firstName
      firstLastName
      secondLastName
      rejections {
        id
        reason
        internalReason
        contactData
        economicData
        personalData
        createdAt
        updatedAt
        type
        status
      }
      createdAt
      createdBy
      acceptedAt
      status
    }
  }
`
