import React, { forwardRef, Ref, ReactElement, ReactNode } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { StyledCloseButton } from './styled'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

interface Props {
  closeModalFunction: () => void
  isOpen: boolean
  children: ReactNode
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const GenericModal = ({ closeModalFunction, isOpen, children }: Props) => {
  return (
    <Dialog TransitionComponent={Transition} open={isOpen}>
      <StyledCloseButton onClick={closeModalFunction}>
        <CloseIcon />
      </StyledCloseButton>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

export default GenericModal
