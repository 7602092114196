import { gql } from '@apollo/client'

export const GET_ALL_PROMOTERS = gql`
  query GetPromoters(
    $search: String!
    $page: Int!
    $limit: Int!
    $orderBy: String!
    $orderDirection: String!
  ) {
    getPromoters(
      search: $search
      page: $page
      limit: $limit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      promoters {
        id
        status
        firstName
        creditBalance
        creditLine
        firstLastName
        phoneNumber
        secondLastName
        userId
        createdAt
        deviceId
        phoneLada
        pendingAmount
        totalToPay
        numberActiveClients
      }
      total
    }
  }
`
