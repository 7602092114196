import { createSlice } from '@reduxjs/toolkit'
import { AWSStructure } from 'types'

interface UserState {
  user: AWSStructure | null
  isAuthenticated: boolean
}

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload.user
      state.isAuthenticated = action.payload.isAuthenticated
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
      localStorage.clear()
    },
  },
})

const { actions } = userSlice

export { actions }

export default userSlice.reducer
