import { FC, ReactElement } from 'react'
import { Container, StyledImage, TextContainer, TopContent } from './styled'
import { Button, Typography } from '@mui/material'
import { theme } from 'theme/theme'
import { SizeEmptyErrorState } from 'types'

type Props = {
  icon?: string
  widthIcon?: number
  size?: string
  textDescription: string
  buttonLabel?: string
  handleButton?: () => void
  leftIconButton?: ReactElement
  extraText?: string
}

const EmptyErrorState: FC<Props> = ({
  icon,
  widthIcon,
  size = SizeEmptyErrorState.LARGE,
  textDescription,
  buttonLabel,
  handleButton,
  leftIconButton,
  extraText,
}) => {
  return (
    <Container>
      <TopContent>
        {icon && <StyledImage src={icon} alt="icon empty/error" widthIcon={widthIcon} />}
        <TextContainer>
          <Typography
            variant={size === SizeEmptyErrorState.LARGE ? 'h3' : 'button'}
            color={theme.typographyColor.primaryText}
          >
            {textDescription}
          </Typography>
          {extraText && (
            <Typography variant="body2" color={theme.typographyColor.primaryText}>
              {extraText}
            </Typography>
          )}
        </TextContainer>
      </TopContent>
      {buttonLabel && handleButton && (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleButton}
          startIcon={leftIconButton}
        >
          {buttonLabel}
        </Button>
      )}
    </Container>
  )
}

export default EmptyErrorState
