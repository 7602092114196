import styled from '@emotion/styled'
import { Typography, Alert } from '@mui/material'
import { theme } from 'theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
  flex: 1 0 0;
`
export const TypographyLabel = styled(Typography)`
  color: ${theme.typographyColor.secondaryText};
`

export const TypographyNameLabel = styled(Typography)`
  color: ${theme.typographyColor.primaryText};
`

export const StyledAlert = styled(Alert)`
  width: 100%;
  font-family: ${theme.typography.fontFamily};
  color: ${theme.typographyColor.errorText};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const StyledSuccessContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const StyledSuccessTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
export const TypographySuccessLabel = styled(Typography)`
  color: ${theme.typographyColor.primaryText};
  text-align: center;
`

export const TypographySuccessDescriptionLabel = styled(Typography)`
  color: ${theme.typographyColor.secondaryText}
  text-align: center;
`
