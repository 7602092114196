import { CreditByStatus, CreditStatus, PaymentSortModel, PaymentType } from 'types'
import { OPTIONS_ROWS_PER_PAGE, ORDER, ORDER_BY_CREATED_AT } from '..'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'
import { GET_CREDIT_BY_STATUS } from '../queries'
import { useEffect, useState } from 'react'
import { columnsRejected } from '../Columns'
import { NetworkError } from 'components/CustomDataGrid/EmptyStates/NetworkError'
import { RequestError } from 'components/CustomDataGrid/EmptyStates/RequestError'
import { DateRange } from 'react-day-picker'
import { useQuery } from '@apollo/client'
import { NoData } from 'components/CustomDataGrid/EmptyStates/NoData'
import CustomDataGrid from 'components/CustomDataGrid'

interface RejectedProps {
  dateRange: DateRange | undefined
  inputFilter: string
  handleFilterButton: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Rejected = ({ dateRange, inputFilter, handleFilterButton }: RejectedProps) => {
  const [isOnlineState, setIsOnlineState] = useState<boolean>(navigator.onLine)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(OPTIONS_ROWS_PER_PAGE[0])
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [rejectedPayments, setRejectedPayments] = useState<CreditByStatus[]>([])
  const [totalPayments, setTotalPayments] = useState<number>(0)

  const { loading, data, error, refetch } = useQuery(GET_CREDIT_BY_STATUS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: page,
      limit: rowsPerPage,
      order: ORDER,
      orderBy: ORDER_BY_CREATED_AT,
      filters: {
        statuses: CreditStatus.REJECTED,
        clientName: filterSearch,
        datesFilter: {
          dates: {
            startDate: dateRange?.from?.toISOString(),
            endDate: dateRange?.to?.toISOString(),
          },
          filterField: PaymentSortModel.UPDATED,
        },
      },
    },
  })

  useEffect(() => {
    if (!loading && !error && data) {
      const rejectedCredits: CreditByStatus[] = data?.getCreditsByStatus ?? []
      setTotalPayments(rejectedCredits.length)
      setRejectedPayments(rejectedCredits)
    }
  }, [data, loading, dateRange])

  useEffect(() => {
    setPage(0)
    setFilterSearch(inputFilter)
  }, [inputFilter])

  const sortModel: GridSortModel = [{ field: ORDER_BY_CREATED_AT, sort: ORDER }]
  const initialStateGrid: GridInitialStateCommunity = {
    pagination: {
      paginationModel: {
        page: page,
        pageSize: rowsPerPage,
      },
    },
  }

  const handleErrorButton = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      refetch()
    }
  }

  const handleChangePage = (objectPages: GridPaginationModel) => {
    setPage(objectPages.page)
    setRowsPerPage(objectPages.pageSize)
  }

  const hasNoData =
    rejectedPayments.length === 0 && filterSearch.length === 0 && !dateRange && !loading

  if (!isOnlineState) return <NetworkError handleRetry={handleErrorButton} />
  if (error) return <RequestError handleRetry={handleErrorButton} />
  if (hasNoData) return <NoData message="No hay órdenes de pago rechazadas" />

  return (
    <CustomDataGrid
      title="Listado de créditos rechazados"
      loading={loading}
      data={rejectedPayments}
      dataColumns={columnsRejected()}
      initialState={initialStateGrid}
      pageSizeOptions={OPTIONS_ROWS_PER_PAGE}
      handleChangePage={handleChangePage}
      sort={sortModel}
      page={page}
      pageSize={rowsPerPage}
      totalRows={totalPayments}
      filterButton={{ handleClick: handleFilterButton }}
    />
  )
}

export default Rejected
