//TODO add mutation
import { gql } from '@apollo/client'

export const REMOVE_PAYOUT = gql`
  mutation RemoveDisbursement($removeDisbursementId: String!) {
    removeDisbursement(id: $removeDisbursementId) {
      id
      clientFullName
    }
  }
`
