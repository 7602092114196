import { EmptyContainer } from 'components/common/styled'
import EmptyErrorState from 'components/EmptyErrorState'

interface NetworkErrorProps {
  handleRetry: () => void
}

export const NetworkError = ({ handleRetry }: NetworkErrorProps) => {
  return (
    <EmptyContainer paddingVertical={75}>
      <EmptyErrorState
        textDescription="No tienes conexión a internet"
        extraText="Revisa tu conexión de internet e inténtalo de nuevo."
        buttonLabel="Volver a intentar"
        handleButton={handleRetry}
      />
    </EmptyContainer>
  )
}
