import { FC, useContext } from 'react'
import { Typography } from '@mui/material'
import { BottomContent } from '../styled'
import IconCheckmarkPink from '../../../assets/checkmarkPink.png'
import { ContainerSuccess, StyledButton } from './styled'
import { theme } from 'theme/theme'
import { Promoter } from '..'
import { AlertContext } from 'hooks/AlertContext'

type Props = {
  selectedPromoter: Promoter | null
  handleCloseSteps: () => void
}

const PromoterAddedSuccessfully: FC<Props> = ({ selectedPromoter, handleCloseSteps }) => {
  const { showSnackbar } = useContext(AlertContext)
  const fullName = `${selectedPromoter?.firstName} ${selectedPromoter?.firstLastName} ${selectedPromoter?.secondLastName}`

  const handleCloseModal = () => {
    showSnackbar('Promotora Agregada')
    handleCloseSteps()
  }

  return (
    <BottomContent gap={24}>
      <img src={IconCheckmarkPink} alt="checkmark pink icon" />
      <ContainerSuccess>
        <Typography variant="h1" color={theme.typographyColor.primaryText}>
          ¡Promotora Agregada!
        </Typography>
        <Typography variant="body1" color={theme.typographyColor.secondaryText}>
          Hemos enviado la información de acceso por WhatsApp a{' '}
          <Typography variant="button" component="span" color={theme.typographyColor.secondaryText}>
            {fullName}
          </Typography>
          , al número de teléfono{' '}
          <Typography variant="button" component="span" color={theme.typographyColor.secondaryText}>
            {`+52 ${selectedPromoter?.phoneNumber}`}
          </Typography>
        </Typography>
      </ContainerSuccess>
      <StyledButton variant="outlined" onClick={handleCloseModal}>
        Cerrar
      </StyledButton>
    </BottomContent>
  )
}

export default PromoterAddedSuccessfully
