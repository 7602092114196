import { gql } from '@apollo/client'

export const REJECT_PROSPECT = gql`
  mutation RejectClient($rejectClientId: String!, $rejectClientInfo: RejectClientInfoInput!) {
    rejectClient(id: $rejectClientId, rejectClientInfo: $rejectClientInfo) {
      rejections {
        reason
        internalReason
        contactData
        economicData
        personalData
        status
        type
      }
    }
  }
`
