import { gql } from '@apollo/client'

export const GET_CREDIT_BY_ID = gql`
  query GetCreditById($creditId: String!) {
    getCreditById(creditId: $creditId) {
      id
      clientId
      amount
      weeksSelected
      paymentType
      speiNumber
      videoUrl
      weeklyPayment
      status
      createdBy
      pendingType
      legalPDF
    }
  }
`

export const GET_CLIENT_BY_ID = gql`
  query GetClientsById($clientId: String!) {
    getClientsById(id: $clientId) {
      id
      firstName
      firstLastName
      secondLastName
      phoneNumber
      phoneLada
      status
      rejections {
        id
        reason
        internalReason
        contactData
        economicData
        personalData
        createdAt
        updatedAt
        type
        status
      }
      acceptedAt
      createdAt
      createdBy
      contactData {
        id
        phoneNumber
        phoneLada
        indoorNumber
        outdoorNumber
        state
        zipCode
        suburb
        state
        municipality
        street
      }
      personalData {
        id
        firstName
        firstLastName
        secondLastName
        maritalStatus
        frontINEUrl
        backINEUrl
      }
    }
  }
`

export const GET_PROMOTER_BY_ID = gql`
  query GetPromoterById($promoterId: String!) {
    getPromoterById(promoterId: $promoterId) {
      firstLastName
      firstName
      secondLastName
      creditBalance
    }
  }
`
