import { InfoContainer, StyledAvatar, StyledContainer } from './styled'
import { Typography } from '@mui/material'
import { ContactData, MaritalStatusEnum, PersonalData } from 'types'
import { getFormattedAddress } from './utils'

interface Props {
  firstName: string
  firstLastName: string
  secondLastName: string
  contactData?: ContactData
  personalData?: PersonalData
}

const ProspectData = ({
  firstName,
  firstLastName,
  secondLastName,
  contactData,
  personalData,
}: Props) => {
  const { phoneNumber, phoneLada } = contactData || {}
  const { maritalStatus, photoUrl } = personalData || {}

  const displayMaritalStatus = (noFormattedStatus: string) => {
    switch (noFormattedStatus) {
      case MaritalStatusEnum.SINGLE:
        return 'Soltero(a)'
      case MaritalStatusEnum.MARRIED:
        return 'Casado(a)'
      case MaritalStatusEnum.DIVORCED:
        return 'Divorciado(a)'
      case MaritalStatusEnum.WIDOWED:
        return 'Viudo(a)'
      default:
        return 'Desconocido'
    }
  }

  return (
    <StyledContainer>
      <StyledAvatar src={photoUrl}></StyledAvatar>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Nombre Completo
        </Typography>
        <Typography variant="body1">{`${firstName} ${firstLastName} ${secondLastName}`}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Número de teléfono
        </Typography>
        <Typography variant="body1">{`${phoneLada} ${phoneNumber}`}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Dirección
        </Typography>
        <Typography variant="body1">{getFormattedAddress(contactData)}</Typography>
      </InfoContainer>
      <InfoContainer>
        <Typography variant="body2" color="GrayText">
          Estado Civil
        </Typography>
        <Typography variant="body1"> {`${displayMaritalStatus(maritalStatus || '')}`} </Typography>
      </InfoContainer>
    </StyledContainer>
  )
}

export default ProspectData
