import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const ValidatorLayout = ({ children }: Props) => {
  return <div>{children}</div>
}

export default ValidatorLayout
