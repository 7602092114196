import {
  StyledHeader,
  StyledNameContainer,
  StyledIconButton,
  StyledActionsContainer,
  RejectionIconButton,
  AcceptedIconButton,
  StyledLink,
} from './styled'
import { Button, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import GenericAlert from 'components/GenericAlert'
import { REACT_APP_LENDUS_URL } from 'config/appConfig'
import { PersonalData, StatusClientEnum } from 'types'

interface Props {
  handleClickCloseValidatorScreen: () => void
  handleClickOpenAcceptProspect: () => void
  handleRejectProspect: () => void
  statusClient?: StatusClientEnum
  acceptedAt: string
  userData?: PersonalData
}

const TopBar = ({
  handleClickCloseValidatorScreen,
  handleClickOpenAcceptProspect,
  handleRejectProspect,
  statusClient = StatusClientEnum.PENDING,
  userData,
  acceptedAt,
}: Props) => {
  const { firstName, firstLastName, secondLastName } = userData || {}
  const renderContent = (index: StatusClientEnum) => {
    switch (index) {
      case StatusClientEnum.PENDING:
        return (
          <>
            <RejectionIconButton
              startIcon={<HighlightOffIcon />}
              variant="outlined"
              onClick={handleRejectProspect}
            >
              <Typography variant="body1">Rechazar Prospecto</Typography>
            </RejectionIconButton>
            <AcceptedIconButton
              startIcon={<CheckCircleOutlineIcon />}
              variant="outlined"
              onClick={handleClickOpenAcceptProspect}
            >
              <Typography variant="body1">Aceptar Prospecto</Typography>
            </AcceptedIconButton>
          </>
        )
      case StatusClientEnum.ACCEPTED:
        return (
          <>
            <GenericAlert status="success" title={`Aceptado el ${acceptedAt}`} />
            <StyledLink href={REACT_APP_LENDUS_URL} target="_blank">
              <Button variant="outlined" endIcon={<OpenInNewIcon />}>
                Ver en Lendus
              </Button>
            </StyledLink>
          </>
        )

      case StatusClientEnum.PREREJECTED:
        return (
          <>
            <RejectionIconButton
              startIcon={<HighlightOffIcon />}
              variant="outlined"
              onClick={handleRejectProspect}
            >
              <Typography variant="body1">Notificar rechazo</Typography>
            </RejectionIconButton>
          </>
        )

      case StatusClientEnum.REJECTED:
        return null

      default:
        return
    }
  }

  return (
    <StyledHeader>
      <StyledNameContainer>
        <StyledIconButton onClick={handleClickCloseValidatorScreen}>
          <Close />
        </StyledIconButton>
        <Typography variant="h3">{` ${firstName} ${firstLastName} ${secondLastName} `}</Typography>
      </StyledNameContainer>
      <StyledActionsContainer>{renderContent(statusClient)}</StyledActionsContainer>
    </StyledHeader>
  )
}

export default TopBar
