import styled from '@emotion/styled'
import { theme } from 'theme/theme'

export const Container = styled.div`
  display: flex;
  padding: 16px 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid ${theme.extraColors?.stroke01};
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`
