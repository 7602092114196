import styled from '@emotion/styled'
import { Chip } from '@mui/material'
import { theme } from 'theme/theme'

export const Container = styled.div<{ severity: 'error' | 'success' }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.severity === 'error'
      ? `${theme.typographyColor.errorText}`
      : `${theme.typographyColor.onSuccess}`};
`
export const StyledChip = styled(Chip)`
  background-color: ${theme.extraBackgrounds.activeChip};
  color: ${theme.typographyColor.onSuccess};
  .MuiChip-icon {
    color: ${theme.typographyColor.onSuccess};
  }
`
