import { FC, useState, useEffect, useMemo } from 'react'
import { Prospect, StatusClientEnum } from 'types'
import CustomDataGrid from 'components/CustomDataGrid'
import { prospectRejectedColumns } from './Columns'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { GET_PROMOTERS_BY_STATUS } from '../queries'
import { NetworkError, NoData, RequestError } from 'components/CustomDataGrid/EmptyStates'
import { getInitialStateGrid, INITIAL_ROWS_PER_PAGE, optionsRowsPerPage } from 'utils'
import { GridPaginationModel } from '@mui/x-data-grid'

const NO_PROSPECTS = 0
const STEP_ID = 3

type Props = {
  totalItems: number
  filter: string
  actualStep: number
}

const Rejected: FC<Props> = ({ totalItems, actualStep, filter }) => {
  const [filterSearchProspects, setFilterSearchProspects] = useState<string>('')
  const [prospectsRejected, setProspectsRejected] = useState<Prospect[]>([])
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const initialStateGrid = getInitialStateGrid({ page, rowsPerPage })
  const queryVars = useMemo(
    () => ({
      fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
      notifyOnNetworkStatusChange: true,
      variables: {
        page: page,
        limit: rowsPerPage,
        search: filter,
        orderBy: 'createdAt',
        statuses: [StatusClientEnum.REJECTED, StatusClientEnum.CANCELED],
      },
    }),
    [page, rowsPerPage, filter],
  )
  const { loading, data, error, refetch } = useQuery(GET_PROMOTERS_BY_STATUS, queryVars)

  const handleChangePage = ({ page, pageSize }: GridPaginationModel) => {
    setPage(page)
    setRowsPerPage(pageSize)
  }

  const handleReloadInfo = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      refetch()
    }
  }

  useEffect(() => {
    if (actualStep === STEP_ID) {
      setFilterSearchProspects(filter)
    }
  }, [actualStep, filter])

  useEffect(() => {
    if (!loading && data) {
      setProspectsRejected(data?.getClientsListByStatus)
    }
  }, [data, loading])

  const hasNoData = prospectsRejected.length === NO_PROSPECTS && loading === false

  if (!isOnlineState) return <NetworkError handleRetry={handleReloadInfo} />
  if (error) return <RequestError handleRetry={handleReloadInfo} />
  if (hasNoData) return <NoData message="No hay ningún prospecto rechazado" />

  return (
    <CustomDataGrid
      loading={loading}
      data={prospectsRejected}
      dataColumns={prospectRejectedColumns}
      nameFilter={filterSearchProspects}
      title="Listado de prospectos pendientes de verificar"
      filterMode="client"
      totalRows={totalItems}
      handleChangePage={handleChangePage}
      initialState={initialStateGrid}
      pageSizeOptions={optionsRowsPerPage}
      paginationMode="server"
    />
  )
}

export default Rejected
