export interface Config {
  NODE_ENV?: string
  REACT_APP_LENDING_GRAPHQL_URL: string
  REACT_APP_LENDUS_URL: string
}

const config: Config = {
  NODE_ENV: process.env.REACT_APP_NODE_ENV || '',
  REACT_APP_LENDING_GRAPHQL_URL: process.env.REACT_APP_LENDING_GRAPHQL_URL || '',
  REACT_APP_LENDUS_URL: process.env.REACT_APP_LENDUS_URL || '',
}

export default config
