import { FC, useState, useEffect } from 'react'
import GenericModal from 'components/GenericModal'
import { Container } from './styled'
import { Prospect, StatusClientEnum } from 'types'
import RejectNotify from './RejectNotify'
import RejectedClient from './RejectedClient'

type Props = {
  isOpenModal: boolean
  closeModal: () => void
  getProspectDetails: () => void
  selectedProspect: Prospect
}

enum STEP {
  ONE = 1,
  TWO = 2,
}

const INITIAL_STEP = 1

const ModalNotifyRejectProspect: FC<Props> = ({
  isOpenModal,
  closeModal,
  getProspectDetails,
  selectedProspect,
}) => {
  const [actualStep, setActualStep] = useState<STEP>(INITIAL_STEP)
  const [modalTypeName, setModalTypeName] = useState<string>('')

  useEffect(() => {
    if (!isOpenModal) {
      setActualStep(INITIAL_STEP)
    }
    if (isOpenModal && selectedProspect.status === StatusClientEnum.PENDING)
      setModalTypeName('Rechazar prospecto')
    else if (isOpenModal && selectedProspect.status === StatusClientEnum.PREREJECTED)
      setModalTypeName('Notificar rechazo')
  }, [isOpenModal, selectedProspect])

  const handleChangeStep = (step: number) => setActualStep(step)

  const handleCloseSteps = () => {
    if (actualStep === STEP.TWO) {
      getProspectDetails()
    }
    closeModal()
  }

  const stepsComponents = {
    [STEP.ONE]: (
      <RejectNotify
        titleAndLabel={modalTypeName}
        handleChangeStep={handleChangeStep}
        selectedProspect={selectedProspect}
      />
    ),
    [STEP.TWO]: (
      <RejectedClient selectedProspect={selectedProspect} handleCloseSteps={handleCloseSteps} />
    ),
  }

  return (
    <GenericModal isOpen={isOpenModal} closeModalFunction={handleCloseSteps}>
      <Container>{stepsComponents[actualStep]}</Container>
    </GenericModal>
  )
}

export default ModalNotifyRejectProspect
