import { ReactNode, FunctionComponent } from 'react'
import { Container, DashboardLayoutChildrenContent } from './styled'
import Sidebar from 'components/Sidebar'

type Props = {
  children: ReactNode
}

const DashboardLayout: FunctionComponent<Props> = ({ children }) => {
  return (
    <Container>
      <Sidebar />
      <DashboardLayoutChildrenContent>{children}</DashboardLayoutChildrenContent>
    </Container>
  )
}

export default DashboardLayout
