import { FC, useState, ReactElement } from 'react'
import { Container, RightContent } from './styled'
import { Button, Typography } from '@mui/material'
import SearchInput from 'components/common/SearchInput'
import { theme } from 'theme/theme'

type Props = {
  title: string
  labelSearch: string
  handleSearch: (text: string) => void
  inputWidth?: number
  button?: {
    label: string
    disabled?: boolean
    handleButton: (event: React.MouseEvent<HTMLButtonElement>) => void
    variant: 'text' | 'outlined' | 'contained'
    icon: ReactElement
    color: 'primary' | 'secondary' | 'success'
  }
  trimSearchText?: boolean
  isInputDisabled?: boolean
}

const Header: FC<Props> = ({
  title,
  labelSearch,
  handleSearch,
  inputWidth,
  trimSearchText,
  button,
  isInputDisabled,
}) => {
  const [searchText, setSearchText] = useState<string>('')

  const handleOnSearch = (text: string) => {
    const newText = trimSearchText ? text.trim() : text
    setSearchText(newText)
    handleSearch(newText)
  }

  return (
    <Container>
      <Typography variant="title" color={theme.typographyColor.primaryText}>
        {title}
      </Typography>
      <RightContent>
        <SearchInput
          disabled={isInputDisabled}
          width={inputWidth}
          text={searchText}
          handleChangeText={handleOnSearch}
          placeholder={labelSearch}
        />
        {button && (
          <Button
            disabled={button.disabled}
            variant={button.variant}
            color={button.color}
            disableElevation
            onClick={button.handleButton}
            startIcon={button.icon}
          >
            {button.label}
          </Button>
        )}
      </RightContent>
    </Container>
  )
}

export default Header
