import { BankTransfersTabs, PaymentOrderStep } from 'types'
import { FC, useState } from 'react'
import { getTabProps } from 'utils'
import { Tab, Tabs } from '@mui/material'
import { StyledBox } from 'components/common/styled'
import TabPanel from 'components/common/TabPanel'
import Header from 'components/Header'

const BankTransfers: FC = () => {
  const [currentTab, setCurrentTab] = useState<PaymentOrderStep>(PaymentOrderStep.PENDINGS)

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab)
  }

  return (
    <>
      <Header
        title="Transferencias bancarias"
        labelSearch="Buscar transferencias"
        handleSearch={() => {
          // TODO: Add function to handle rows search
        }}
      />
      <StyledBox>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
          variant="fullWidth"
          aria-label="Bank transfers tabs"
        >
          <Tab
            // TODO: Display count from api
            // label={`Pendientes (${paymentCount.pending || '0'})`}
            label="Pendientes"
            {...getTabProps(BankTransfersTabs.PENDING)}
          />
          <Tab
            // TODO: Display count from api
            // label={`Aceptados (${paymentCount.applied || '0'})`}
            label="Aceptados "
            {...getTabProps(BankTransfersTabs.ACCEPTED)}
          />
          <Tab
            // TODO: Display count from api
            // label={`Desembolsados (${paymentCount.disbursed || '0'})`}
            label="Desembolsados"
            {...getTabProps(BankTransfersTabs.DISBURSED)}
          />
          <Tab
            // TODO: Display count from api
            // label={`Rechazados (${paymentCount.disbursed || '0'})`}
            label="Rechazados"
            {...getTabProps(BankTransfersTabs.REJECTED)}
          />
        </Tabs>
      </StyledBox>
      <TabPanel value={currentTab} index={BankTransfersTabs.PENDING}></TabPanel>
      <TabPanel value={currentTab} index={BankTransfersTabs.ACCEPTED}></TabPanel>
      <TabPanel value={currentTab} index={BankTransfersTabs.DISBURSED}></TabPanel>
      <TabPanel value={currentTab} index={BankTransfersTabs.REJECTED}></TabPanel>
    </>
  )
}

export default BankTransfers
