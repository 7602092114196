import styled from '@emotion/styled'
import { Button, ToggleButton, Typography } from '@mui/material'
import { theme } from 'theme/theme'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 48px;
`
export const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  overflow-y: auto;
  max-height: 80vh;
`

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  object-fit: contain;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 80vh;
  width: 48.9vw;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 24px;
  border: 1px solid var(--system-colors-stroke-01, #eee);
  background: var(--backgrounds-paper-03, #fff);
  margin-top: 16px;
`

export const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  flex-direction: column;
  min-width: 7rem;
  min-height: 8.625rem;
`

export const StyledIconContainer = styled.img`
  border: 1px solid var(--system-colors-stroke-01, #eee);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px;
  border-bottom: none;
`

export const StyledButton = styled(Button)`
  && {
    background-color: #2196f3; /* Default background color */
    color: #fff; /* Default text color */
    transition: background-color 0.3s ease; /* Add transition for smooth effect */

    &:hover {
      background-color: #1976d2; /* Change background color on hover */
    }

    &:active {
      background-color: #0d47a1; /* Change background color on active */
    }
  }
`
export const TextContainer = styled.div`
  max-width: 70px;
  word-wrap: break-word;
  display: inline-block;
`

export const DownloadButton = styled(Button)`
  .MuiSvgIcon-root {
    color: ${theme.palette.secondary.main};
  }
  border-radius: 8px;
  border: 1px solid ${theme.palette.secondary.main};
  color: ${theme.palette.secondary.main};
  padding: 12px 16px;
  &:hover {
    border: 1px solid ${theme.palette.secondary.main};
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const LabelTypography = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`

export const Container = styled.div``
