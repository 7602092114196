import { FC, useState, useEffect, useMemo } from 'react'
import { Prospect, StatusClientEnum } from 'types'
import CustomDataGrid from 'components/CustomDataGrid'
import { useNavigate } from 'react-router-dom'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { GET_PROMOTERS_BY_STATUS } from '../queries'
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid'
import MenuButton from './MenuButton'
import {
  FormattedDateEnum,
  formattedDate,
  removeAccents,
  getInitialStateGrid,
  INITIAL_ROWS_PER_PAGE,
  optionsRowsPerPage,
} from 'utils'
import { NetworkError, NoData, RequestError } from 'components/CustomDataGrid/EmptyStates'

const NO_PROSPECTS = 0
const STEP_ID = 2

type Props = {
  totalItems: number
  filter: string
  actualStep: number
}

const Accepted: FC<Props> = ({ totalItems, filter, actualStep }) => {
  const [filterSearchProspects, setFilterSearchProspects] = useState<string>('')
  const [prospectsAccepted, setProspectsAccepted] = useState<Prospect[]>([])
  const navigate = useNavigate()
  const [isOnlineState, setIsOnlineState] = useState(navigator.onLine)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const initialStateGrid = getInitialStateGrid({ page, rowsPerPage })
  const queryVars = useMemo(
    () => ({
      fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
      notifyOnNetworkStatusChange: true,
      variables: {
        page: page,
        limit: rowsPerPage,
        search: filter,
        orderBy: 'createdAt',
        statuses: [StatusClientEnum.ACCEPTED],
      },
    }),
    [page, rowsPerPage, filter],
  )
  const { loading, data, error, refetch } = useQuery(GET_PROMOTERS_BY_STATUS, queryVars)

  const acceptedColumns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Nombre',
      flex: 1.2,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { firstName, firstLastName, secondLastName } = row
        const fullName = removeAccents(`${firstName} ${firstLastName} ${secondLastName}`)
        return `${fullName}`
      },
      renderCell: ({ row }: GridRenderCellParams) => {
        const { firstName, firstLastName, secondLastName } = row
        return <div>{`${firstName} ${firstLastName} ${secondLastName}`}</div>
      },
    },
    {
      field: 'createdBy',
      headerName: 'Promotora',
      flex: 1.2,
      valueGetter: ({ row }: GridRenderCellParams) => {
        const { createdBy } = row
        return createdBy
      },
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de solicitud',
      flex: 0.7,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const date = formattedDate(value as string, FormattedDateEnum.FULL)
        return date
      },
    },
    {
      field: 'acceptedAt',
      headerName: 'Fecha de aceptación',
      flex: 0.7,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        const date = formattedDate(value as string, FormattedDateEnum.SMALL)
        return date
      },
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      align: 'right',
      getActions: ({ row }: GridRowParams) => [
        <MenuButton
          key={row.id}
          handleSeeRequest={() => {
            navigate(`/validate/${row.id}`)
          }}
          idProspect={row.id}
        />,
      ],
    },
  ]

  const handleChangePage = ({ page, pageSize }: GridPaginationModel) => {
    setPage(page)
    setRowsPerPage(pageSize)
  }

  useEffect(() => {
    if (actualStep === STEP_ID) {
      setFilterSearchProspects(filter)
    }
  }, [actualStep, filter])

  useEffect(() => {
    if (!loading && data) {
      setProspectsAccepted(data?.getClientsListByStatus)
    }
  }, [data, loading])

  const handleReloadInfo = () => {
    setIsOnlineState(navigator.onLine)
    if (navigator.onLine) {
      refetch()
    }
  }

  const hasNoData = prospectsAccepted.length === NO_PROSPECTS && loading === false

  if (!isOnlineState) return <NetworkError handleRetry={handleReloadInfo} />
  if (error) return <RequestError handleRetry={handleReloadInfo} />
  if (hasNoData) return <NoData message="Todavía no tienes ningún prospecto aceptado" />

  return (
    <CustomDataGrid
      loading={loading}
      data={prospectsAccepted}
      dataColumns={acceptedColumns}
      nameFilter={filterSearchProspects}
      title="Listado de prospectos aceptados"
      filterMode="client"
      totalRows={totalItems}
      handleChangePage={handleChangePage}
      initialState={initialStateGrid}
      pageSizeOptions={optionsRowsPerPage}
      paginationMode="server"
    />
  )
}

export default Accepted
