import { FC } from 'react'
import { Container, LeftContent } from './styled'
import { Button, Typography } from '@mui/material'
import { theme } from 'theme/theme'
import { Promoter } from '..'

type Props = {
  promoter: Promoter
  handleChangeStep: (step: number) => void
  handleSelectedPromoter: (promoter: Promoter) => void
}

const NEXT_STEP = 2

const ElementPromoterFound: FC<Props> = ({
  promoter,
  handleChangeStep,
  handleSelectedPromoter,
}) => {
  const fullName = `${promoter?.firstName} ${promoter?.firstLastName} ${promoter?.secondLastName}`
  const handleClickSelect = () => {
    handleSelectedPromoter(promoter)
    handleChangeStep(NEXT_STEP)
  }

  return (
    <Container>
      <LeftContent>
        <Typography variant="body1" color={theme.typographyColor.primaryText}>
          {fullName}
        </Typography>
        <Typography variant="body2" color={theme.typographyColor.secondaryText}>
          {`+52 ${promoter.phoneNumber}`}
        </Typography>
      </LeftContent>
      <Button variant="outlined" onClick={handleClickSelect}>
        Seleccionar
      </Button>
    </Container>
  )
}

export default ElementPromoterFound
