import {
  ButtonContainer,
  Container,
  DownloadButton,
  ImageContainer,
  LabelContainer,
  LabelTypography,
  StyledToggleButton,
  TextContainer,
  MainContainer,
} from './styled'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import IneFrontIcon from 'assets/ineFrontIcon.svg'
import IneBackIcon from 'assets/ineBackIcon.svg'
import DocumentIcon from 'assets/docIcon.svg'
import VideoIcon from 'assets/videoDocumentIcon.svg'
import { DocumentsTypes } from '../types'
import { ApolloError } from 'apollo-client'
import Viewer from '../Viewer'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { AlertContext } from 'hooks/AlertContext'
import DownloadIcon from 'assets/downloadIcon.svg'

const images = {
  [DocumentsTypes.INE_FRONT]: IneFrontIcon,
  [DocumentsTypes.INE_BACK]: IneBackIcon,
  [DocumentsTypes.ACCEPTANCE_VIDEO]: VideoIcon,
  [DocumentsTypes.LEGAL_PDF]: DocumentIcon,
}

const labels = {
  [DocumentsTypes.INE_FRONT]: 'INE Frontal',
  [DocumentsTypes.INE_BACK]: 'INE Reverso',
  [DocumentsTypes.ACCEPTANCE_VIDEO]: 'Video de aceptación',
  [DocumentsTypes.LEGAL_PDF]: 'Pagaré',
}

interface Props {
  creditId?: string
  options: { type: DocumentsTypes; url: string }[]
  error?: ApolloError
  refetch: () => void
}

const DocumentsSelectorViewer: FunctionComponent<Props> = ({
  options,
  error,
  creditId,
  refetch,
}: Props) => {
  const { showSnackbar } = useContext(AlertContext)
  const [selectedType, setSelectedType] = useState<DocumentsTypes>(DocumentsTypes.INE_FRONT)
  const [selectedUrl, setSelectedUrl] = useState<string>('')
  const isLegalPdf = selectedType === DocumentsTypes.LEGAL_PDF

  const handleSelect = (newType: DocumentsTypes, newUrl: string) => {
    if (selectedType !== newType) {
      setSelectedType(newType)
      setSelectedUrl(newUrl)
    }
  }

  const handleDownloadPDFOnClick = async () => {
    try {
      const response = await fetch(selectedUrl)
      const blob = await response.blob()
      const pdfBlob = new Blob([blob], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(pdfBlob)
      const tempLink = document.createElement('a')
      tempLink.href = url
      const fileName = typeof creditId === 'string' ? creditId : 'pagare'
      tempLink.setAttribute('download', `${fileName}.pdf`)
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      showSnackbar('Ocurrió un problema. Inténtalo de nuevo más tarde.', 'error')
    }
  }

  useEffect(() => {
    const defaultSelectedUrl =
      options.find(({ type }) => type === DocumentsTypes.INE_FRONT)?.url || ''
    setSelectedUrl(defaultSelectedUrl)
  }, [options])

  return (
    <MainContainer>
      <Container>
        <LabelContainer>
          <LabelTypography>{labels[selectedType]}</LabelTypography>
          {isLegalPdf && (
            <DownloadButton onClick={handleDownloadPDFOnClick}>
              <img src={DownloadIcon} alt="downloadIcon.svg" />
              Descargar
            </DownloadButton>
          )}
        </LabelContainer>
        <ImageContainer>
          <Viewer
            selectedUrl={selectedUrl}
            error={error}
            refetch={refetch}
            selectedType={selectedType}
          />
        </ImageContainer>
      </Container>
      <ButtonContainer>
        {options.map(({ type, url }) => (
          <StyledToggleButton
            key={`${type}`}
            aria-label="list"
            value={type}
            selected={type === selectedType}
            onClick={() => handleSelect(type, url)}
          >
            <img src={images[type]} alt="icon" />
            <TextContainer>
              <Typography variant="body3">{labels[type]}</Typography>
            </TextContainer>
          </StyledToggleButton>
        ))}
      </ButtonContainer>
    </MainContainer>
  )
}

export default DocumentsSelectorViewer
