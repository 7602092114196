import styled from '@emotion/styled'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  overflow-y: hidden;
`

export const DashboardLayoutChildrenContent = styled.div`
  flex: 1;
  padding: 48px 40px 40px;
  overflow-y: auto;
`
